<template>
  <div>
    <button @click.stop="showCartDrawer = true" class="side-cart-btn text-center fw-500 fs-12 d-none d-lg-block">
      <span class="d-block">
        <i class="las la-shopping-cart"></i>
        <span>{{cartList.length}} {{$t('items')}}</span>
      </span>
      <span class="d-block white primary--text rounded mt-1 px-1 py-2 lh-1">${{totalCount}}</span>
    </button>
    <v-navigation-drawer class="cart-drawer" v-model="showCartDrawer" width="400" fixed right temporary clipped hide-overlay>
      <div>
        <v-list-item class="d-flex pa-4 border-bottom side-cart-top">
          <i class="la la-shopping-cart la-3x me-2 text-primary"></i>
          <div class="lh-1-4">
            <div class="fs-16 fw-500">{{cartList.length}} {{$t('items')}}</div>
            <div class="fs-12 opacity-60">Shop</div>
          </div>
          <button @click.stop="showCartDrawer = false" type="button" class="ms-auto">
            <i class="la la-close fs-20"></i>
          </button>
        </v-list-item>
        <div v-if="!cartList.length" class="px-5 py-2 side-cart-content">
          <div class="d-flex flex-column justify-center h-100 text-center pa-5">
            <img src="@/assets/img/no-cart-item.jpg" alt="$t('your_shopping_bag_is_empty_start_shopping')" class="img-fluid" />
            <div class="fs-20">
              {{$t('your_shopping_bag_is_empty_start_shopping')}}
            </div>
          </div>
        </div>
        <div v-else class="px-5 py-2 c-scrollbar side-cart-content">
          <v-list dense>
            <div>
              <v-list-item v-for="(item, index) in cartList" :key="index" class="px-0 py-3" :class="{'border-top':index != 0}">
                <div class="w-100">
                  <div class="position-relative">
                    <div class="d-flex align-center">
                      <v-checkbox v-model="item.isSelect" class="mt-0 pt-0" hide-details on-icon="la-check"></v-checkbox>
                      <div class="flex-shrink-0 lh-0">
                        <router-link :to="`/product/${item.productId}`" target="_blank">
                          <img :src="item.iconUrl" :alt="item.name" class="img-fluid size-70px">
                        </router-link>
                      </div>
                      <div>
                        <div class="d-flex align-center">
                          <div class="flex-grow-1 minw-0 ms-3">
                            <router-link :to="`/product/${item.productId}`" target="_blank" class="text-reset">
                              <div class="text-truncate-2 fs-12 opacity-80 mb-2">{{item.name}}</div>
                            </router-link>
                            <div class="d-flex align-center">
                              <v-btn @click="editCount(item, -1,true)" class="rounded btn-xxs" elevation="0" fab x-small color="primary"><i class="las la-minus"></i></v-btn>
                              <span class="mx-3 fs-12">{{item.quantity}}</span>
                              <v-btn @click="editCount(item, 1)" class="rounded btn-xxs" elevation="0" fab x-small color="primary"><i class="las la-plus"></i></v-btn>
                            </div>
                          </div>
                          <div class="flex-shrink-0 w-80px text-end">
                            <div class="text-primary">${{(item.discountPrice*item.quantity).toFixed(2)}}</div>
                          </div>
                          <div class="ms-4">
                            <button @click="editCount(item, -item.quantity)"><i class="las la-trash fs-20 opacity-50"></i></button>
                          </div>
                        </div>
                        <div v-if="item.attrs?.length" class="mt-2 fs-12 opacity-50 ms-3">
                          <!-- <template v-for="(item, index) in item.attrs" :key="index">{{ item.value }}; </template> -->
                          {{ item.attrs.map(e=>e.value).join("; ") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>
          </v-list>
        </div>
        <v-list-item class="pa-4 border-top side-cart-bottom d-block">
          <div class="row mt-0 mb-3 row--dense align-center">
            <div class="fs-13 fw-600 opacity-50 flex-shrink-0 col col-auto">
              {{$t('have_a_code')}}
            </div>
            <div class="fw-500 opacity-80 col">
              <v-text-field class="form-control form-control-sm white" outlined :placeholder="$t('coupon_code')" hide-details></v-text-field>
            </div>
            <div class="fw-700 col col-auto">
              <v-btn disabled @click="applyClick()" class="white--text darken-3 text-capitalize px-5" elevation="0" small color="grey" block>{{$t('apply')}}</v-btn>
            </div>
          </div>
          <v-btn @click="checkout()" elevation="0" large color="primary" block>{{$t('checkout')}} ${{totalCount}}</v-btn>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="showLoginDialog" width="1000">
      <LoginBox></LoginBox>
    </v-dialog>
  </div>
</template>

<script>
import LoginBox from './widget/LoginBox.vue';

export default {
  props: {
    isFooter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showCartDrawer: false,
      showLoginDialog: false,
    };
  },
  computed: {
    cartList: (vm) => vm.$store.state.cart,
    selectCartList: (vm) => vm.cartList.filter(ele => ele.isSelect),
    totalCount: (vm) => vm.selectCartList.reduce((n, m) => n + (m.discountPrice * m.quantity), 0).toFixed(2),
    systemOptions: (vm) => vm.$store.state.system,
  },
  methods: {
    applyClick () {
      this.showLoginDialog = true
    },
    async editCount (item, quantity) {
      // this.$showSnackbar.error(this.$t('minimum_purchase_qty') + ": " + this.systemOptions.orderQuantityMinLimit)
      // if (isMinus && item.quantity <= this.systemOptions.orderQuantityMinLimit) {
      //   return this.$showSnackbar.error(this.$t('minimum_purchase_qty') + ": " + this.systemOptions.orderQuantityMinLimit)
      // }
      // this.$store.dispatch("cart/requestEditCart", { item, quantity })
      const data = await this.$api.updateCart(item.cartId, quantity < 0 ? `N${quantity * -1}` : quantity)
      if (data.state == "ok") {
        this.$store.dispatch("cart/requestCartList");
      } else {
        this.$showSnackbar.error(data.msg)
      }
    },
    checkout () {
      if (!this.selectCartList.length) {
        this.$showSnackbar.error(this.$t('please_select_a_cart_product'))
      } else {
        this.$router.push("/checkout")
      }
    }
  },
  components: {
    LoginBox,
  },
  created () {
  },
};
</script>

<style lang="scss" scoped>
.cart-drawer {
  z-index: 99999;
}

.side-cart-content {
  height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>
