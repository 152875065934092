import $api from '@/api'
import $storage from '@/storage'
import Vue from 'vue'

const state = $storage.getUserInfo()

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    Object.keys(userInfo).forEach(key => {
      Vue.set(state, key, userInfo[key])
    });
    $storage.setUserInfo(state)
  },
  REMOVE_USER_INFO: (state) => {
    Object.keys(state).forEach(key => {
      Vue.set(state, key, null)
    });
    $storage.removeUserInfo()
  },
}

const actions = {
  requestUserInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      $api.getUserInfo().then(res => {
        if (res.state == "ok") {
          commit('SET_USER_INFO', res.msg)
        }
        resolve({ 'roles': ['admin'] })
      }).catch(error => {
        reject(error)
      })
    })
  },
  requestBalance ({ commit }) {
    return new Promise((resolve) => {
      $api.walletBalance().then(res => {
        if (res.state == "ok") {
          commit('SET_USER_INFO', { balance: res.msg })
        } else {
          resolve(res)
        }
      }).catch(error => {
        resolve({ state: "fail", msg: error.message })
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
