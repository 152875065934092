import $api from '@/api'
import Vue from 'vue'

const state = {
    unread: 0
}

const mutations = {
    SET_UNREAD_COUNT: (state, num) => {
        Vue.set(state, "unread", num)
    },
}

const actions = {
    requestConsult ({ commit }) {
        return new Promise((resolve) => {
            $api.consult().then(res => {
                if (res.state == "ok") {
                    commit('SET_UNREAD_COUNT', res.msg)
                }
                resolve(true)
            }).catch(() => {
                resolve(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
