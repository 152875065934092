<template>
  <div class="row" :class="mainClass">
    <div class="col-md-6 col-lg-5 col-12">
      <div>
        <ProductThumbs v-if="detailMsg?.imageUrl" :dataList="detailMsg.imageUrl"></ProductThumbs>
        <template v-else>
          <v-skeleton-loader class="mb-4" height="420" type="image"></v-skeleton-loader>
          <div class="row gutters-10">
            <div v-for="(item, index) in 4" :key="index" class="col">
              <v-skeleton-loader class="mb-2" height="90" type="image"></v-skeleton-loader>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-md-6 col-lg-7 col">
      <template v-if="detailMsg">
        <h1 class="fs-21 opacity-80 mb-3 lh-1-3">{{detailMsg.name}}</h1>
        <div class="d-flex fs-12 flex-wrap">
          <div class="d-flex col-sm-6 col-md-6 col-lg-5 col-12">
            <span class="me-3 opacity-50"> {{reviewMsg.ratingAvg.toFixed(2)}} </span>
            <v-rating class="lh-1-5" readonly :value="reviewMsg.ratingAvg" :half-increments="true" full-icon="la-star" empty-icon="la-star" half-icon="la-star half" size="12" background-color></v-rating>
            <span class="ms-3 opacity-50"> ({{reviewMsg.ratingCount}} {{$t('ratings')}}) </span>
          </div>
          <div class="ms-auto abount_that">
            <div class="d-flex col-sm-12 col-md-12 col-lg-12 col-12" style="padding-left: 0px;">
              <button @click="showQuery()" class="primary--text pa-1 lh-1 d-flex align-center">
                <i class="la la-comment ts-02 fs-14 me-1"></i>
                <span class="fw-700">{{$t('ask_about_this_product')}}</span>
              </button>
            </div>
          </div>
          <div class="v-dialog__container" style="z-index: 601; padding: 0px 50px;"></div>
          <div class="ms-2">
            <div class="d-flex col-sm-12 col-md-12 col-lg-12 col-12" style="padding-left: 0px;">
              <button @click="addWishlist()" class="primary--text pa-1 lh-1 d-flex align-center">
                <i class="la ts-02 fs-14 me-1" :class="isOnWish ? 'la-heart' : 'la-heart-o'"></i>
                <span class="fw-700">{{isOnWish ? $t('remove_from_wishlist') : $t('add_to_wishlist')}}</span>
              </button>
            </div>
          </div>
        </div>
        <hr role="separator" aria-orientation="horizontal" class="mb-4 mt-2 v-divider theme--light">
        <div class="lh-1">
          <div class="row">
            <div class="py-2 col-lg-2 col-3"><span class="opacity-60 fs-12">{{$t('price')}}</span></div>
            <div class="py-2 col-lg-10 col-9">
              <span v-if="detailMsg.price == detailMsg.discountPrice" class="primary--text fs-24 fw-500"> ${{detailMsg.price.toFixed(2)}} </span>
              <template v-else>
                <span class="grey--text fs-24 fw-100 text-decoration-line-through">${{detailMsg.price.toFixed(2)}}</span>
                <span class="primary--text fs-24 fw-500">${{detailMsg.discountPrice.toFixed(2)}}</span>
              </template>
              <span class="fs-12 opacity-50 ms-1"> /unit </span>
            </div>
          </div>
        </div>
        <div class="border-top mt-4"></div>
        <div class="row mt-1">
          <div class="col-lg-2 col-3"><span class="opacity-60 fs-12">{{$t('sale_num')}}</span></div>
          <div class="col-lg-10 col-9"><span class="opacity-60 fs-12"> {{ detailMsg.sales }} </span></div>
        </div>
        <div class="row mt-1 gutters-5">
          <div class="col-sm-6 col-12">
            <div class="bg-soft-primary border border-primary rounded px-4 py-3 lh-1 h-100">
              <div class="d-flex align-center">
                <i class="las la-check green rounded-circle size-15px fs-8 white--text d-inline-flex align-center justify-center me-3"></i>
                <span class="fw-700 text-truncate">{{$t('in_stock')}}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="grey lighten-3 border-gray-300 rounded px-4 py-3 d-flex lh-1-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.847" viewBox="0 0 20 19.847">
                <g id="Group_1226" data-name="Group 1226" transform="translate(-7 -11)">
                  <path id="Path_3097" data-name="Path 3097" d="M16.6,44.374a4.283,4.283,0,0,1-2-.723,17.011,17.011,0,0,1-1.733-1.1l-2.451.735a.445.445,0,0,1-.395-.071,9.261,9.261,0,0,1-2.986-3.853.444.444,0,0,1,.381-.614,10.4,10.4,0,0,0,3.718-.941,10.209,10.209,0,0,0,1.178-.647,11.321,11.321,0,0,1,1.654-.962,10.309,10.309,0,0,1,1.092-.4c.033-.009.089-.024.162-.045l.074-.023c.072-.022.138-.043.3-.084s.351-.088.6-.139a5.637,5.637,0,0,1,1.723-.14,3.7,3.7,0,0,1,.763.137c.33.1.625.219.911.335a8.959,8.959,0,0,0,.941.342,4.288,4.288,0,0,0,.845.153c.283.027.636.061.788.435a.886.886,0,0,1-.083.772,1.892,1.892,0,0,1-2.139.971c-.123-.019-.234-.04-.337-.064a4.139,4.139,0,0,0,.479.3,4.183,4.183,0,0,0,2.044.481h1.342a.444.444,0,0,1,.442.4,1.841,1.841,0,0,1-.18,1.047,2.5,2.5,0,0,1-2.672,1.066c-.933.726-1.875,1.408-2.8,2.029a2.986,2.986,0,0,1-1.555.607ZM12.95,41.62a.444.444,0,0,1,.265.088,16.105,16.105,0,0,0,1.813,1.166c1.155.636,1.473.619,1.626.611a2.129,2.129,0,0,0,1.107-.458c.959-.643,1.935-1.353,2.9-2.111a.442.442,0,0,1,.359-.086,1.779,1.779,0,0,0,1.916-.566.784.784,0,0,0,.059-.15h-.862a5.045,5.045,0,0,1-4.245-2.151,4.185,4.185,0,0,0-.571,0c-.08.006-.147.013-.191.018a.444.444,0,1,1-.1-.883c.053-.006.133-.015.232-.021a5.166,5.166,0,0,1,.931.021H18.2a3.4,3.4,0,0,1,.95.265,3.725,3.725,0,0,0,.927.269,1.336,1.336,0,0,0,.487.032,1.232,1.232,0,0,0,.678-.452,5.07,5.07,0,0,1-.955-.178,9.83,9.83,0,0,1-1.031-.373c-.278-.113-.541-.22-.831-.307a2.794,2.794,0,0,0-.581-.1,4.834,4.834,0,0,0-1.472.126c-.23.047-.416.092-.554.128s-.192.053-.253.072l-.085.026c-.085.025-.15.043-.176.05a9.7,9.7,0,0,0-.967.359,10.647,10.647,0,0,0-1.522.889,11.069,11.069,0,0,1-4.7,1.679,8.364,8.364,0,0,0,2.269,2.793l2.444-.733a.443.443,0,0,1,.128-.019Z" transform="translate(0 -13.528)" fill="#b8b8b8"></path>
                  <path id="Path_3098" data-name="Path 3098" d="M31,26.555H25.929a.444.444,0,0,1,0-.889h4.627V11.889H15.889V24.831a.444.444,0,1,1-.889,0V11.444A.444.444,0,0,1,15.444,11H31a.444.444,0,0,1,.444.444V26.111A.444.444,0,0,1,31,26.555Z" transform="translate(-4.444)" fill="#b8b8b8"></path>
                  <path id="Path_3099" data-name="Path 3099" d="M32.5,15.444H28.944A.444.444,0,0,1,28.5,15V11.444A.444.444,0,0,1,28.944,11H32.5a.444.444,0,0,1,.444.444V15A.444.444,0,0,1,32.5,15.444Zm-3.111-.889h2.667V11.889H29.389Z" transform="translate(-11.944)" fill="#b8b8b8"></path>
                </g>
              </svg>
              <div class="ms-3">
                <div class="fw-700 text-truncate"> {{$t('estimated_delivery_time')}} <span class="fs-12 opacity-50">{{detailMsg.expressDay}} {{$t('days')}}</span> </div>
                <!-- <div class="fs-12 opacity-50"> {{detailMsg.expressDay}} {{$t('days')}} </div> -->
              </div>
            </div>
          </div>
        </div>

        <div v-for="(arr, key, index) in this.attriList" :key="index" class="row">
          <div class="col-lg-2 col-3"><span class="opacity-60 fs-12 text-capitalize">{{ key == "color_img" ? "color" : key }}</span></div>
          <div class="col-lg-10 col-9" style="padding: 0;">
            <div class="attr-item" v-for="(item, idx) in arr" :key="idx">
              <div @click="changeAttr(key,item)" :class="[{'active':crtAttr[`${key}`] == item}, key == 'color_img' ? 'img-item' : 'square-item']" :title="item.name">
                <template v-if="key == 'color_img'">
                  <img :src="item.imageUrl" :alt="item.name">
                </template>
                <template v-else>
                  {{ item }}
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-3"><span class="opacity-60 fs-12">{{$t('quantity')}}</span></div>
          <div class="col-lg-10 col-9">
            <vue-numeric-input v-model="quantity" :min="1" :max="Number(detailMsg.stockQuantity)" :step="1" align="center" size="132px"></vue-numeric-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-3"><span class="opacity-60 fs-12">{{$t('total_price')}}</span></div>
          <div class="col-lg-10 col-9"><span class="primary--text fs-24 fw-600"> ${{(detailMsg.discountPrice * (isNaN(quantity) ? 0 : quantity)).toFixed(2)}} </span></div>
        </div>
        <div class="my-5">
          <v-btn @click="addCart()" elevation="0" block color="grey" :disabled="isNaN(quantity) || quantity < detailMsg.minPurchaseQuantity" class="darken-4 white--text">
            {{(isNaN(quantity) || quantity < detailMsg.minPurchaseQuantity) ?  $t('minimum_purchase_qty') +": "+ this.detailMsg.minPurchaseQuantity : $t('add_to_cart') }}
          </v-btn>
        </div>
        <div class="row">
          <div class="col-lg-2 col-3"><span class="opacity-60 fs-12">{{$t('share')}}</span></div>
          <div class="col-lg-10 col-9">
            <div>
              <a v-for="(item, index) in shareList" :key="index" @click="openWindow(item)" href="javascript:void(0)" :class="`share-network-${item.name}`"><i :class="`${item.icon}`"></i></a>
            </div>
          </div>
        </div>
      </template>
      <v-skeleton-loader v-else class="mb-3" type="table-cell, sentences, table-cell, divider, table-cell, table-row"></v-skeleton-loader>
    </div>
    <v-dialog v-model="showQueryDialog" width="600">
      <v-card>
        <v-toolbar class="d-block lighten-4" dense flat color="grey" height="48">
          <v-toolbar-title class="fw-600">{{$t('any_query_about_this_product')}}</v-toolbar-title>
          <v-btn @click="showQueryDialog = false" class="ms-auto" fab rounded small icon>
            <i class="las la-times fs-24"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4 text-dark">
          <div class="white pa-5 rounded">
            <v-form ref="form">
              <!-- <div class="mb-3">
                <div class="mb-1 fs-13 fw-500">{{$t('title')}}</div>
                <v-text-field :value="queryTitle" disabled :rules="queryTitleRules" :placeholder="$t('title')" outlined hide-details="auto" dirty></v-text-field>
              </div> -->
              <div class="mb-3">
                <div class="mb-1 fs-13 fw-500">{{$t('message')}}</div>
                <v-textarea v-model="queryContent" :rules="queryContentRules" :placeholder="$t('message')" outlined hide-details="auto" dirty rows="4"></v-textarea>
              </div>
              <div class="text-right mt-4">
                <v-btn @click="requestQuery()" :loading="isLoading" :disabled="isLoading" elevation="0" color="primary">{{$t('send')}}</v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoginDialog" width="1000">
      <LoginBoxVue :redictRouter="null" @loginSuccess="showLoginDialog = false"></LoginBoxVue>
    </v-dialog>
  </div>
</template>

<script>
import ProductThumbs from '@/components/ProductThumbs'
import VueNumericInput from 'vue-numeric-input'
import LoginBoxVue from './LoginBox.vue'

export default {
  data () {
    return {
      queryTitle: null,
      queryTitleRules: [
        v => !!v?.trim() || this.$t('this_field_is_required'),
      ],
      queryContent: null,
      queryContentRules: [
        v => !!v?.trim() || this.$t('this_field_is_required'),
      ],
      isLoading: false,
      detailMsg: null,
      quantity: 1,
      reviewMsg: null,
      showQueryDialog: false,
      showLoginDialog: false,
      shareList: [
        {
          "icon": "lab la-facebook-f",
          "name": "facebook",
          "link": "https://www.facebook.com/sharer/sharer.php?u=@u&title=@t"
        },
        {
          "icon": "lab la-twitter",
          "name": "twitter",
          "link": "https://twitter.com/intent/tweet?text=@t&url=@u"
        },
        {
          "icon": "lab la-pinterest-p",
          "name": "pinterest",
          "link": "https://pinterest.com/pin/create/button/?url=@u&description=@t"
        },
        {
          "icon": "lab la-linkedin-in",
          "name": "linkedin",
          "link": "https://www.linkedin.com/sharing/share-offsite/?url=@u"
        },
        {
          "icon": "las la-envelope",
          "name": "email",
          "link": "mailto:?subject=@t&body=@u"
        },
        {
          "icon": "lab la-whatsapp",
          "name": "whatsapp",
          "link": "https://api.whatsapp.com/send?text=@t%0D%0A@u"
        },
      ],
      attriList: {},
      crtAttr: {},
    }
  },
  computed: {
    isOnWish () {
      return this.$store.state.wishlist.findIndex((ele) => ele.id == this.id) != -1;
    }
  },
  watch: {
    showQueryDialog (val) {
      if (val) {
        this.queryTitle = this.detailMsg.name
      }
    }
  },
  props: ["mainClass", "id"],
  components: {
    ProductThumbs,
    VueNumericInput,
    LoginBoxVue
  },
  methods: {
    openWindow (msg) {
      var width = 626;
      var height = 436;
      var left = (screen.width / 2) - (width / 2);
      var top = (screen.height / 2) - (height / 2);

      var shareLink = msg.link
      shareLink = shareLink.replace(/@u/g, encodeURIComponent(window.location.href))
      shareLink = shareLink.replace(/@t/g, encodeURIComponent(this.detailMsg.name))
      window.open(shareLink, "", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top);
    },
    showQuery () {
      if (!this.$store.state.user.token) {
        this.showLoginDialog = true
        return
      }

      this.showQueryDialog = true
    },
    async addCart () {
      if (!this.$store.state.user.token) {
        this.showLoginDialog = true
        return
      }


      const obj = {
        productId: this.detailMsg.id,
        name: this.detailMsg.name,
        price: this.detailMsg.price,
        discountPrice: this.detailMsg.discountPrice,
        iconUrl: this.detailMsg.iconUrl,
        attrs: []
      }

      Object.keys(this.crtAttr).forEach(key => {
        if (key == 'color_img') {
          obj.attrs.push({ "key": key, "value": this.crtAttr[key].name })
        } else {
          obj.attrs.push({ "key": key, "value": this.crtAttr[key] })
        }
      })

      // const reslut = await this.$store.dispatch("cart/requestEditCart", { item: obj, quantity: this.quantity })
      const data = await this.$api.addCart(obj.productId, this.quantity, { attrs: JSON.stringify(obj.attrs) })
      if (data.state == "ok") {
        this.$showSnackbar.success(this.$t('product_added_to_cart'))
        this.$store.dispatch("cart/requestCartList");
      } else {
        this.$showSnackbar.error(data.msg)
      }
    },
    async addWishlist () {
      if (!this.$store.state.user.token) {
        this.showLoginDialog = true
        return
      }

      this.$store.commit("wishlist/EDIT_WISH_LIST", this.detailMsg)
      const data = await this.$api.wishlistAdd(this.detailMsg.id)
      if (data.state == "ok") {
        this.$store.dispatch("wishlist/requestWishlistList")
      }
    },
    init () {
      Promise.all([this.$api.productDetails(this.id), this.$api.review(this.id)]).then(datas => {
        if (datas[0].state == "ok") {
          this.detailMsg = datas[0].msg

          this.attriList = this.detailMsg.attriList
          if ("color_img" in this.attriList && "color" in this.attriList) {
            delete this.attriList.color
          }

          Object.keys(this.attriList).forEach(key => {
            this.crtAttr[`${key}`] = this.attriList[`${key}`][0]
          })

          if ("color_img" in this.attriList) {
            this.$set(this.detailMsg.imageUrl, 0, this.crtAttr.color_img.imageUrl);
          }
          this.$emit("onDetailMsg", this.detailMsg)
        } else {
          this.$showSnackbar.error(datas[0].msg)
        }
        if (datas[1].state == "ok") {
          this.reviewMsg = datas[1].msg
          this.$emit("onReview", this.reviewMsg)
        } else {
          this.$showSnackbar.error(datas[1].msg)
        }
      })
    },
    async requestQuery () {
      if (!this.$refs.form.validate()) return
      const params = {
        // queryTitle: this.queryTitle.trim(),
        productId: this.id,
        content: this.queryContent.trim(),
      }
      this.isLoading = true
      const data = await this.$api.consultAdd(params)
      this.isLoading = false
      if (data.state == "ok") {
        this.$showSnackbar.success(this.$t('message_has_been_sent_to_seller'))
        this.$refs.form.reset()
        this.showQueryDialog = false
        setTimeout(() => {
          const routerJump = this.$router.resolve({ path: "/user/product-query" })
          window.open(routerJump.href, "_blank")
        }, 500);
      } else {
        this.$showSnackbar.error(data.msg)
      }
    },
    changeAttr (key, value) {
      const obj = Object.assign({}, this.crtAttr)
      obj[`${key}`] = value
      this.crtAttr = obj
      if (key == "color_img") {
        this.$set(this.detailMsg.imageUrl, 0, this.crtAttr.color_img.imageUrl);
      }
    },
  },
  created () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.attr-item {
  display: inline-block;
  padding: 6px;
  & > div {
    padding: 6px;
    border-radius: 2px;
    cursor: pointer;
  }
  .square-item {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    &.active {
      border: 1px solid red;
      color: red;
    }
  }
  img {
    width: 38px;
    height: 50px;
    object-fit: contain;
  }
  .img-item {
    border: 1px solid #ccc;
    &.active {
      border-color: red;
    }
  }
}
</style>