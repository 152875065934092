
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import $storage from '@/storage'
// import en from '@/locales/en_US.json'

Vue.use(VueI18n)

// 获取语言
const lang = $storage.getSelectLang
// 装载语言
// const messages = {}
const i18n = new VueI18n({
    // locale: lang,
    // messages: messages,
    // fallbackLocale: en, // 缺省语言 map表没有对应语言 使用该默认语言
    silentTranslationWarn: true,
    // missing: (locale, key) => {
    //     console.log('语言map表缺失该语言', locale, key)
    // }
})

//重点：import 函数，动态变化路径`./${lang}/index.js`
import( /*webpackChunkName:"language_pack"*/ `@/locales/${lang}.json`).then((languagePack) => {
    i18n.setLocaleMessage(lang, {
        ...languagePack.default
    });
    i18n.locale = lang
});
export default i18n
