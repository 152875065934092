import $storage from '@/storage'

const state = $storage.getSearchList

const mutations = {
    ADD_SEARCH_LIST: (state, text) => {
        const index = state.findIndex(ele => ele == text)
        if (index == -1) { // 不存在,添加
            state.unshift(text)
        }
        if (state.length > 15) {
            state.pop()
        }
        $storage.setSearchList(state)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
