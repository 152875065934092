export const openServices = (type, uid) => {
    if (type === 1) {
        meiqiaInit()
        meiqiaOpen(uid)
    }
}

export const meiqiaInit = () => (function (a, b, c, d, e, j, s) {
    a[d] = a[d] || function () {
        (a[d].a = a[d].a || []).push(arguments)
    };
    j = b.createElement(c),
        s = b.getElementsByTagName(c)[0];
    j.async = true;
    j.charset = 'UTF-8';
    j.src = 'https://static.meiqia.com/widget/loader.js';
    s.parentNode.insertBefore(j, s);
})(window, document, 'script', '_MEIQIA');


export const getServiceLan = () => {
    var lang = localStorage.getItem("select-lang") ?? "en_US";
    var serviceLang = 'en'
    switch (lang) {
        case "en_US":
            serviceLang = 'en'
            break
        case "es_ES":
            serviceLang = 'es'
            break
        case "fr_FR":
            serviceLang = 'fr'
            break
        case "id_ID":
            serviceLang = 'en'
            break
        case "it_IT":
            serviceLang = 'it'
            break
        case "ja_JP":
            serviceLang = 'ja'
            break
        case "ko_KR":
            serviceLang = 'ko'
            break
        case "ms_MY":
            serviceLang = 'en'
            break
        case "pt_PT":
            serviceLang = 'pt'
            break
        case "ru_RU":
            serviceLang = 'ru'
            break
        case "th_TH":
            serviceLang = 'th'
            break
        case "vi_VN":
            serviceLang = 'vi'
            break
        case "zh_CN":
            serviceLang = 'cn'
            break
        case "zh_TW":
            serviceLang = 'tw'
            break
        default:
            serviceLang = 'en'
            break
    }
    return serviceLang;
}

export const meiqiaOpen = (entId) => {
    /* eslint-disable */
    _MEIQIA('entId', entId);
    _MEIQIA('language', getServiceLan())
    const userInfo = localStorage.getItem("User-Info") ? JSON.parse(localStorage.getItem("User-Info")) : null
    _MEIQIA('metadata', { 'name': userInfo?.id })
    /* eslint-enable */
}