import CryptoJS from 'crypto-js'

export function encrypt (word) {
    if (word === null || word === '') {
        return word
    }
    let keyStr = 'HengJia500Lotter'
    let ivStr = 'dMitHORyqbeYVE0o'
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let iv = CryptoJS.enc.Utf8.parse(ivStr)
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()

}

export function dencrypt (word) {
    if (word === null || word === '') {
        return word
    }
    let keyStr = 'HengJia500Lotter'
    let ivStr = 'dMitHORyqbeYVE0o'
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let iv = CryptoJS.enc.Utf8.parse(ivStr)
    let encrypted = CryptoJS.AES.decrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(encrypted).toString()

}