<template>
  <div class="rounded-lg pa-5 border border-gray-200 overflow-hidden white shadow-light">
    <div class="row no-gutters align-center">
      <div class="col-lg-6 order-lg-1 col-12 order-2">
        <div class="mt-5 mt-lg-0">
          <span>
            <router-link to="/user/login" aria-current="page" class="router-link-exact-active router-link-active text-reset d-block lh-0">
              <img src="/uploads/all/95xIpGgPvl1IqRVs8l0hNNf2Q0hSOK3FmX4uS5R1.jpg" alt="Shop" class="img-fluid w-100" />
            </router-link>
          </span>
        </div>
      </div>
      <div class="col-lg-6 order-lg-2 col-12 order-1">
        <div class="px-lg-7">
          <h1 class="text-uppercase lh-1 mb-6">
            <span class="opacity-50 fs-22 fw-400">login_with</span>
            <span class="d-block display-1 fw-900 primary--text"> Shop </span>
            <span class="opacity-50 fs-22 fw-400">{{$t('account')}}</span>
          </h1>
          <v-form ref="form" v-model="valid">
            <div v-if="isEmail" class="mb-4">
              <div class="mb-1 fs-13 fw-500">{{$t('email')}}</div>
              <v-text-field @keyup.enter.native="loginClick()" hide-details="auto" outlined v-model="email" :rules="emailRules" required :placeholder="$t('email_address')"></v-text-field>
            </div>
            <div v-else class="mb-4">
              <div class="mb-1 fs-13 fw-500">{{$t('phone_number')}}</div>
              <vue-tel-input @keyup.enter.native="loginClick()" ref="phoneDom" :customValidate="new RegExp('^[0-9]*$')" :autoDefaultCountry="false" :inputOptions="inputOptions" :dropdownOptions="dropdownOptions" v-model="phone"></vue-tel-input>
              <div v-if="phoneErrorMessage" class="v-text-field__details mt-2 pl-3">
                <div role="alert" class="v-messages theme--light error--text">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message"> {{phoneErrorMessage}} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <v-btn class="p-0 opacity-50 text-reset h-20px mb-2" elevation @click="changeLogType()">{{isEmail ? $t('change_phone') : $t('change_email')}}</v-btn>
            </div>
            <div>
              <div class="mb-1 fs-13 fw-500">{{$t('password')}}</div>
              <v-text-field @keyup.enter.native="loginClick()" hide-details="auto" outlined v-model="password" :rules="passwordRules" required placeholder="* * * * * * * *" :type="showPassword ? 'text':'password'" :append-icon="showPassword ? 'la-eye':'la-eye-slash' " @click:append="appendClick()"></v-text-field>
              <div class="text-end font-italic fs-12 opacity-70"><router-link to="/user/forgot-password" class="primary--text"> {{$t('forgot_password')}} </router-link></div>
            </div>
            <v-btn class="px-16 mb-4" :loading="isLoading" :disabled="isLoading" x-large elevation color="primary" @click="loginClick()">{{$t('login')}}</v-btn>
          </v-form>
          <div> {{$t('dont_have_an_account')}}, <router-link to="/user/registration" class="primary--text text-decoration-underline"> {{$t('signup')}} </router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt, dencrypt } from '@/utils/crypto'
export default {
  data () {
    return {
      dropdownOptions: {
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true
      },
      inputOptions: {
        placeholder: "phone number",
        type: "tel",
      },
      showPassword: false,
      isEmail: true,
      valid: true,
      email: null,
      password: null,
      phone: null,
      emailRules: [
        v => !!v || this.$t('this_field_is_required'),
        v => /.+@.+\..+/.test(v) || this.$t('this_field_is_required_a_valid_email'),
      ],
      passwordRules: [
        v => !!v?.trim() || this.$t('this_field_is_required'),
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      isLoading: false
    }
  },
  props: {
    redictRouter: {
      type: String,
      default: "/user/dashboard"
    }
  },
  computed: {
    phoneErrorMessage () {
      if (!this.phone?.trim()) {
        return this.$t('this_field_is_required')
      }
      return null
    }
  },
  methods: {
    appendClick () {
      this.showPassword = !this.showPassword
    },
    changeLogType () {
      this.isEmail = !this.isEmail
      this.$refs.form.reset()
      this.phone = null
    },
    rememberAccount () {
      if (this.isEmail) {
        localStorage.setItem("email", this.email.trim())
        localStorage.setItem("password", encrypt(this.password.trim()))
      }
    },
    async loginClick () {
      if (!this.$refs.form.validate() || (!this.isEmail && this.phoneErrorMessage)) {
        return
      }

      this.isLoading = true
      const params = this.isEmail ? {
        logType: "1",
        userType: "0",
        email: this.email.trim(),
        password: encrypt(this.password.trim()),
      } : {
        logType: "2",
        userType: "0",
        nationCode: this.$refs.phoneDom.activeCountry.dialCode,
        mobile: this.phone,
        password: encrypt(this.password.trim()),
      }
      const data = await this.$api.applogin(params)
      this.isLoading = false
      if (data.state == "ok") {
        this.$showSnackbar.success(this.$t('successfully_logged_in'))
        this.$store.commit('user/SET_USER_INFO', { token: data.msg, id: data.userId });
        this.$store.dispatch("user/requestUserInfo")
        this.rememberAccount()
        this.$emit("loginSuccess")
        if (this.redictRouter) {
          this.$router.replace(this.redictRouter)
        }
      } else {
        this.$showSnackbar.error(data.msg)
      }
    },
  },
  mounted () {
    this.email = localStorage.getItem("email")
    this.password = dencrypt(localStorage.getItem("password"))
  }
}
</script>

<style></style>
