<template>
  <footer class="grey darken-4 pt-8 white--text pb-14 pb-md-10 pb-lg-0 mt-auto">
    <div class="container">
      <div class="border-bottom pb-7 border-gray-800 mb-6">
        <img src="/uploads/all/logo_high.png" class="mw-100" />
      </div>
      <div class="row">
        <div class="col-sm-8 col-md-6 col-lg-3 col-12">
          <div class="primary--text fs-14 fw-700 mb-7">{{$t('get_your_special_offers_coupons__more')}}</div>
          <v-form ref="form">
            <v-text-field class="white mb-2" hide-details="auto" outlined :placeholder="$t('your_email_address')"></v-text-field>
            <v-btn class="px-12 mb-4 primary--text" outlined elevation="0">{{$t('subscribe')}}</v-btn>
          </v-form>
        </div>
        <div class="mb-5 d-none d-lg-block col-lg-2 offset-lg-1 col-12">
          <h4 class="primary--text mb-4">{{$t('service_info')}}</h4>
          <ul class="list-unstyled ps-0 fs-13">
            <li v-for="(item, index) in list1" :key="index" class="py-2">
              <span>
                <router-link :to="item.path" class="text-reset">{{item.name}}</router-link>
              </span>
            </li>
          </ul>
        </div>
        <div class="mb-5 d-none d-lg-block col-lg-2 col-12">
          <h4 class="primary--text mb-4">{{$t('my_account')}}</h4>
          <ul class="list-unstyled ps-0 fs-13">
            <li v-for="(item, index) in list2" :key="index" class="py-2">
              <span>
                <router-link :to="item.path" class="text-reset">{{item.name}}</router-link>
              </span>
            </li>
          </ul>
        </div>
        <div class="mb-5 d-none d-lg-block col-lg-2 col-12">
          <h4 class="primary--text mb-4">{{$t('about_us')}}</h4>
          <ul class="list-unstyled ps-0 fs-13">
            <li class="py-2">
              <span><router-link to="/about-us" class="text-reset">{{ $t('about_us') }}</router-link></span>
            </li>
            <li class="py-2">
              <div class="opacity-50">
                <i class="las la-envelope me-3 mb-2"></i> {{$t('email')}}
              </div>
              <div> {{systemInfo.email}} </div>
            </li>
          </ul>
        </div>
        <div class="mb-5 d-none d-lg-block col-lg-2 col-12">
          <h4 class="primary--text mb-4">{{$t('seller_zone')}}</h4>
          <ul class="list-unstyled ps-0 fs-13">
            <li class="py-2">
              <router-link to="/register-shop" class="text-reset">
                {{$t('be_a_seller')}}
              </router-link>
            </li>
            <li class="py-2">
              <a :href="systemInfo.sellerLink" target="_blank" class="text-reset">
                {{$t('login_to_seller_panel')}}
              </a>
            </li>
          </ul>
        </div>
        <div class="d-lg-none mb-5 col col-12">

          <v-expansion-panels flat accordion>
            <v-expansion-panel class="transparent white--text border-bottom border-gray-800">
              <v-expansion-panel-header class="px-0" expand-icon="la-angle-down white--text fs-14">
                <h4 class="primary--text mb-0"> {{$t('service_info')}} </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="list-unstyled ps-0 fs-13">
                  <li v-for="(item, index) in list1" :key="index" class="py-2"><span>
                      <router-link :to="item.path" class="text-reset"> {{item.name}} </router-link></span>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="transparent white--text border-bottom border-gray-800">
              <v-expansion-panel-header class="px-0" expand-icon="la-angle-down white--text fs-14">
                <h4 class="primary--text mb-0"> {{$t('my_account')}} </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="list-unstyled ps-0 fs-13">
                  <li v-for="(item, index) in list2" :key="index" class="py-2">
                    <span><router-link :to="item.path" class="text-reset"> {{item.name}} </router-link></span>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="transparent white--text border-bottom border-gray-800">
              <v-expansion-panel-header class="px-0" expand-icon="la-angle-down white--text fs-14">
                <h4 class="primary--text mb-0"> {{$t('about_us')}} </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="list-unstyled ps-0 fs-13">
                  <li class="py-2">
                    <span><router-link to="/about-us" class="text-reset">{{ $t('about_us') }}</router-link></span>
                  </li>
                  <li class="py-2">
                    <div class="opacity-50"><i class="las la-envelope me-3 mb-2"></i> {{$t('email')}} </div>
                    <div> {{systemInfo.email}} </div>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="transparent white--text border-bottom border-gray-800">
              <v-expansion-panel-header class="px-0" expand-icon="la-angle-down white--text fs-14">
                <h4 class="primary--text mb-0"> {{$t('seller_zone')}} </h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="list-unstyled ps-0 fs-13">
                  <li class="py-2">
                    <span>
                      <router-link to="/register-shop" class="text-reset"> {{$t('be_a_seller')}} </router-link>
                    </span>
                  </li>
                  <li class="py-2">
                    <a :href="systemInfo.sellerLink" target="_blank" class="text-reset">{{$t('login_to_seller_panel')}}</a>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </div>
      </div>
      <div class="py-3 border-top border-bottom border-gray-800">
        <ul class="list-unstyled d-flex flex-wrap ps-0"></ul>
      </div>
      <div class="row py-5">
        <div class="col-md-12 col-12">
          <div class="lh-1 fs-13">
            <div>
              <div>
                <font color="#626262">{{copyright}}</font>
              </div>
              <div>
                <font color="#626262">By using this site, you agree to its Terms of Service, which
                  prohibit commercial use of any information on this site, and
                  its Privacy Policy. We may earn a commission when you use one
                  of our links to make a purchase.</font>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  computed: {
    systemInfo() {
      return this.$store.state.system_info;
    },
    copyright() {
      return `COPYRIGHT© 2019-2024 ${process.env.VUE_APP_NAME}. ALL RIGHTS RESERVED.`;
    },
    list1() {
      return [
        {
          name: this.$t("terms_and_conditions"),
          path: "/terms",
        },
        {
          name: this.$t("return_policy"),
          path: "/return-policy",
        },
        {
          name: this.$t("privacy_policy"),
          path: "/privacy-policy",
        },
        {
          name: this.$t("seller_policy"),
          path: "/seller-policy",
        },
      ];
    },
    list2() {
      return [
        {
          name: `${this.$t("login")}/${this.$t("signup")}`,
          path: "/user/login",
        },
        {
          name: this.$t("purchase_history"),
          path: "/user/purchase-history",
        },
        {
          name: this.$t("my_wallet"),
          path: "/user/wallet",
        },
        {
          name: this.$t("track_order"),
          path: "/track-order",
        },
        {
          name: this.$t("my_account"),
          path: "/user/profile",
        },
      ];
    },
  },
});
</script>
