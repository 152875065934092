<template>
  <div class="d-flex align-center">
    <div :class="btnClass">
      <button @click="buyNow()" class="text-reset py-1 lh-1 align-center d-flex">
        <i class="la la-shopping-cart fs-20 ts-05 me-1"></i>
        <span class="fw-700 fs-13">{{$t('buy_now')}}</span>
      </button>
    </div>
    <div>
      <button @click="addWishlist()" type="button" class="primary--text pa-1 lh-1">
        <i class="la ts-02 fs-18" :class="isOnWish ? 'la-heart' : 'la-heart-o'"></i>
      </button>
    </div>
    <v-dialog v-model="showBuyDialog" width="1000">
      <v-card>
        <v-toolbar class="d-block lighten-4" dense flat color="grey" height="48">
          <v-toolbar-title class="fw-600">{{$t('add_to_cart')}}</v-toolbar-title>
          <v-btn @click="showBuyDialog = false" class="ms-auto" fab rounded small icon>
            <i class="las la-times fs-24"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4 text-dark">
          <ProductDetail :id="item.id"></ProductDetail>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoginDialog" width="1000">
      <LoginBoxVue :redictRouter="null" @loginSuccess="showLoginDialog = false"></LoginBoxVue>
    </v-dialog>
  </div>
</template>

<script>
import ProductDetail from './ProductDetail.vue'
import LoginBoxVue from './LoginBox.vue'

export default {
  props: ["btnClass", "item"],
  components: {
    ProductDetail,
    LoginBoxVue
  },
  data () {
    return {
      showBuyDialog: false,
      showLoginDialog: false,
    }
  },
  computed: {
    isOnWish () {
      return this.$store.state.wishlist.findIndex((ele) => ele.id == this.item.id) != -1;
    }
  },
  methods: {
    async buyNow () {
      this.showBuyDialog = true
    },
    async addWishlist () {

      if (!this.$store.state.user.token) {
        this.showLoginDialog = true
        return
      }

      this.$store.commit("wishlist/EDIT_WISH_LIST", this.item)
      const data = await this.$api.wishlistAdd(this.item.id)
      if (data.state == "ok") {
        this.$store.dispatch("wishlist/requestWishlistList")
      }
    },
  }
}
</script>

<style>
</style>