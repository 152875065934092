<template>
  <div class="mb-5">
    <div class="container pt-md-6 pb-0 px-0 px-md-3">
      <div class="row gutters-7 md-gutters-10 lh-0">
        <div class="col-lg-6 col-12">
          <swiper :options="options">
            <swiper-slide v-for="(item, index) in list1.products" :key="index">
              <div>
                <span>
                  <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0">
                    <img :src="item.iconUrl" alt="Shop" class="img-fluid w-100">
                  </router-link>
                </span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="col-lg-3 col-6">
          <swiper :options="options">
            <swiper-slide v-for="(item, index) in list2.products" :key="index">
              <div>
                <span>
                  <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0">
                    <img :src="item.iconUrl" alt="Shop" class="img-fluid w-100">
                  </router-link>
                </span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="d-flex justify-space-between flex-column col-lg-3 col-6">
          <swiper :options="options" class="right-first w-100">
            <swiper-slide v-for="(item, index) in list3.products" :key="index">
              <div>
                <span>
                  <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0">
                    <img :src="item.iconUrl" alt="Shop" class="img-fluid w-100">
                  </router-link>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <swiper :options="options" class="w-100">
            <swiper-slide v-for="(item, index) in list4.products" :key="index">
              <div>
                <span>
                  <router-link :to="`/product/${item.id}`" target="_blank" class="text-reset d-block lh-0">
                    <img :src="item.iconUrl" alt="Shop" class="img-fluid w-100">
                  </router-link>
                </span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list1", "list2", "list3", "list4"],
  data () {
    return {
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 2500,
          disableOnInteraction: !1,
        },
      },
      // list1: [
      //   {
      //     "price": 88,
      //     "name": "手机",
      //     "id": 17,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/95lwbEDKTNYIEypnv5T9iXZw6SIvjrctyYeNTBf3.jpg"
      //   },
      //   {
      //     "price": 88,
      //     "name": "笔记本",
      //     "id": 16,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/S4JufjLI8m7RQm1ZhHp2JSYHedzTTtgRTnFDy4CI.jpg"
      //   },
      //   {
      //     "price": 220,
      //     "name": "橱柜0302",
      //     "id": 14,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/ROWS1UlfARDO3WOn7iX3jG3TOefeZopLwb7doRPM.jpg"
      //   },
      // ],
      // list2: [
      //   {
      //     "price": 88,
      //     "name": "手机",
      //     "id": 17,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/swg1r7rsCXXiSl0kwYaOUWu5tFaCZyEOYwTCPNxU.jpg"
      //   },
      //   {
      //     "price": 88,
      //     "name": "笔记本",
      //     "id": 16,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/l7uRhPTDc7J8OHEictQ5zalt0qnmSIPd65SVg8Y8.jpg"
      //   },
      //   {
      //     "price": 220,
      //     "name": "橱柜0302",
      //     "id": 14,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/DtHUc6UJBhD1zDtm2qFunqiNw5qi6KX1zXomFKub.jpg"
      //   },
      // ],
      // list3: [
      //   {
      //     "price": 88,
      //     "name": "手机",
      //     "id": 17,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/JNk2d4pjN6SjrRLH6spVmM9oarkq5bZQZ9t6GqFC.jpg"
      //   },
      //   {
      //     "price": 88,
      //     "name": "笔记本",
      //     "id": 16,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/0ANQ24e0fO8EYW6RXLDIth4v3r1nwJj0rDZ82xLl.jpg"
      //   },
      //   {
      //     "price": 220,
      //     "name": "橱柜0302",
      //     "id": 14,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/25lKk56720XtI0NvrQ5ldBoJGgFcXDfUshamHgJL.jpg"
      //   },
      // ],
      // list4: [
      //   {
      //     "price": 88,
      //     "name": "手机",
      //     "id": 17,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/ouOxOsT3lJHkyqk4nejnmSKODfK3F9HFo2xgBKtr.jpg"
      //   },
      //   {
      //     "price": 88,
      //     "name": "笔记本",
      //     "id": 16,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/lKzp5QpVmj36IgO5fHbscbyqB4WqRYPn72Oa3vwI.jpg"
      //   },
      //   {
      //     "price": 220,
      //     "name": "橱柜0302",
      //     "id": 14,
      //     "iconUrl": "https://seller.soulshop.life/public/uploads/all/Ie8B22MpitKC0ir2TmTbVpGKQvvIs53DCXJwfvnu.jpg"
      //   },
      // ]
    }
  }
}
</script>

<style scoped>
.row.gutters-7 > [class*="col-"] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.col-lg-6 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.col-lg-3:nth-of-type(2) {
  padding-left: 0;
}

.col-lg-3:nth-of-type(3) {
  padding-right: 0;
}

.right-first {
  margin-bottom: 14px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.v-application--is-rtl .col-lg-3:nth-of-type(2) {
  padding-left: 7px;
  padding-right: 0;
}

.v-application--is-rtl .col-lg-3:nth-of-type(3) {
  padding-right: 7px;
  padding-left: 0;
}

@media (min-width: 960px) {
  .right-first {
    margin-bottom: 20px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .row.md-gutters-10 > [class*="col-"] {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .col-lg-6 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .col-lg-3:nth-of-type(2) {
    padding-left: 10px;
  }

  .col-lg-3:nth-of-type(3) {
    padding-right: 10px;
  }

  .v-application--is-rtl .col-lg-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>