import axios from './http'
import Qs from 'qs'
const api = {
    // 系统-语言列表
    systemLang () {
        return axios.post('/pub/system/lang')
    },
    // 系统-产品一级目录
    systemCategory () {
        return axios.post('/pub/system/category')
    },
    // 系统-产品二级目录
    categoryChild (id) {
        return axios.get(`/pub/system/category/child/${id}`)
    },
    // 系统-产品类目-树状
    categoryTree () {
        return axios.get(`/pub/system/category/tree`)
    },
    // 系统-首页推荐平台商品
    homeLibrary () {
        return axios.get(`/pub/home/library`)
    },
    // 系统-首页推荐商铺
    homeSeller (categoryId, storeCount, hasProduct) {
        return axios.get(`/pub/home/seller/${categoryId}-${storeCount}-${hasProduct}`)
    },
    // 系统-品牌按类目
    brandChild (id) {
        return axios.get(`/pub/system/brand/child/${id}`)
    },
    // 系统-品牌按推荐
    brandHot () {
        return axios.get(`/pub/system/brand/hot`)
    },
    // 顾客/商家-登录
    applogin (params) {
        return axios.post(`/app/applogin/login`, Qs.stringify(params))
    },
    // 顾客/商家-注册
    registered (params) {
        return axios.post(`/app/applogin/registered`, Qs.stringify(params))
    },
    // 顾客/商家-注册-手机/邮箱 -(发送验证码)
    sendRegCheckCode (params) {
        return axios.post(`/app/applogin/sendRegCheckCode`, Qs.stringify(params))
    },
    // 顾客/商家-忘记密码-手机/邮箱-(发送验证码)
    sendPwdCheckCode2 (params) {
        return axios.post(`/app/appUserInfo/sendPwdCheckCode2`, Qs.stringify(params))
    },
    // 顾客/商家-忘记密码-(提交效验)
    savePwd (params) {
        return axios.post(`/app/appUserInfo/savePwd`, Qs.stringify(params))
    },
    // 顾客-个人资料
    getUserInfo () {
        return axios.post(`/app/appUserInfo/getUserInfo`)
    },
    // 顾客-购买
    shopping (params) {
        return axios.post(`/app/wallet/shopping`, Qs.stringify({ productsJson: params }))
    },
    // 顾客-购买记录详情
    ShoppingDetail (params) {
        return axios.post(`/app/wallet/ShoppingDetail`, Qs.stringify(params))
    },
    // 顾客-个人购买记录
    buyerShoppings (params) {
        return axios.post(`/app/wallet/buyerShoppings`, Qs.stringify(params))
    },
    // 顾客-我的钱包
    buyerWallet (params) {
        return axios.post(`/app/wallet/buyerWallet`, Qs.stringify(params))
    },
    // 顾客-个人资料-修改
    saveUserInfo (params) {
        return axios.post(`/app/appUserInfo/saveUserInfo`, Qs.stringify(params))
    },
    // 充值
    recharge (params) {
        return axios.post(`/app/deal/recharge`, Qs.stringify(params))
    },
    // 顾客-退款
    refund (params) {
        return axios.post(`/app/wallet/refund`, Qs.stringify(params))
    },
    // 顾客-退款记录
    buyerRefunds (params) {
        return axios.post(`/app/wallet/buyerRefunds`, Qs.stringify(params))
    },
    // 顾客-消息发送
    consultAdd (params) {
        return axios.get(`/app/product/consult/add`, { params })
    },
    // 顾客-消息列表
    consultList (params) {
        return axios.get(`/app/product/consult/list`, { params })
    },
    // 顾客-消息阅读对话
    consultRead (params) {
        return axios.get(`/app/product/consult/read`, { params })
    },
    // 顾客-未读消息数
    consult () {
        return axios.get(`/app/product/consult`)
    },
    // 充值-充值列表
    payList () {
        return axios.post(`/app/pay/payList`)
    },
    // 顾客/商家-钱包余额
    walletBalance () {
        return axios.post(`/app/wallet/walletBalance`)
    },
    // 顾客/商家-运输地址
    getShippingInfo () {
        return axios.post(`/app/appUserInfo/getShippingInfo`)
    },
    // 顾客/商家-运输地址-添加/编辑/删除
    addShippingInfo (params) {
        return axios.post(`/app/appUserInfo/addShippingInfo`, Qs.stringify(params))
    },
    // 顾客/商家-邮箱-手机-修改-（发送 验证码）
    sendCheckCode (params) {
        return axios.post(`/app/appUserInfo/sendCheckCode`, Qs.stringify(params))
    },
    // 顾客/商家-邮箱-手机-修改-提交
    submitMobileOrEmail (params) {
        return axios.post(`/app/appUserInfo/submitMobileOrEmail`, Qs.stringify(params))
    },
    // 顾客-修改心愿单
    wishlistAdd (id) {
        return axios.get(`/app/product/wishlist/add/${id}`)
    },
    // 顾客-查看心愿单
    wishlistList () {
        return axios.get(`/app/product/wishlist`)
    },
    // 顾客-查看购物车
    cartList () {
        return axios.get(`/app/product/cart`)
    },
    // 顾客-加入购物车
    addCart (id, quantity, params) {
        return axios.get(`/app/product/cart/add/${id}-${quantity}`, { params })
    },
    // 顾客-增减购物车
    updateCart (cartid, quantity) {
        return axios.get(`/app/product/cart/update/${cartid}-${quantity}`)
    },
    // 商城-相关商品
    related (params) {
        return axios.get(`/app/product/related`, { params })
    },
    // 商城-商品评价列表
    reviewList (id, params) {
        return axios.get(`/pub/product/review/page/${id}`, { params })
    },
    // 商城-商品评价
    review (id) {
        return axios.get(`/pub/product/review/${id}`)
    },
    // 商城-商品详情页
    productDetails (id) {
        return axios.get(`/app/product/details/${id}`)
    },
    // 顾客-仪表板（余额，上次交易，最近购买，订购产品数）
    buyerIndexInfo () {
        return axios.get(`/app/wallet/buyerIndexInfo`)
    },
    // 商城-所有店铺-搜索
    searchStoreName (params) {
        return axios.get(`/app/product/searchStoreName`, { params })
    },
    // 商城-店铺信息(简略)
    productStoreInfor (id) {
        return axios.get(`/app/product/productStoreInfor/${id}`)
    },
    // 商城-店铺主页
    productStoreDetails (id) {
        return axios.get(`/app/product/productStoreDetails/${id}`)
    },
    // 商城-店铺主页-类别/品牌
    productStoreCategory (id) {
        return axios.get(`/app/product/productStoreCategory/${id}`)
    },
    // 商城-所有商品-搜索-筛选
    search (params) {
        return axios.get(`/app/product/search`, { params })
    },
    // 商城-店铺主页-商品-搜索-筛选
    searchSellerProduct (params) {
        return axios.get(`/app/product/searchSellerProduct`, { params })
    },
    // 七牛云
    qiniuToken () {
        return axios.post(`/qiniu`)
    },
    // 首页-底部-服务信息-详情
    documentText (id) {
        return axios.post(`/conDoc/getInfo/${id}`)
    },
    // 顾客-新增订单商品评价
    reviewAdd (id, params) {
        return axios.post(`/app/product/review/add/${id}`, Qs.stringify(params))
    },
    // 顾客-查看订单商品评价
    reviewCheck (id) {
        return axios.post(`/app/product/review/${id}`)
    },
    // 顾客-新增订单商品评价追评
    reviewLater (id, params) {
        return axios.post(`/app/product/review/later/${id}`, Qs.stringify(params))
    },
    // 顾客-所有广告
    allAd () {
        return axios.get(`/pub/system/brand/hot`)
    },
    // 系统-广告列表
    advertHome () {
        return axios.get(`/pub/system/advert/home`)
    },
    // 系统-开关配置
    systemCon () {
        return axios.get(`/pub/system/con`)
    },
    // 系统-在线客服
    getOnlineService () {
        return axios.get(`/pub/system/onlineService/getOnlineService`)
    },
    // 系统-站点资料
    getSysInfo () {
        return axios.get(`/pub/system/info/getSysInfo`)
    },
    // 系统-获取服务器时间
    systemGetDate () {
        return axios.get(`/pub/system/info/getDate`)
    },
    // 顾客-关注店铺
    follow (storeId) {
        return axios.get(`/app/follow/${storeId}`)
    },
    // 顾客-关注店铺
    followList (params) {
        return axios.get(`/app/follow/list`, { params })
    },
}

export default api
