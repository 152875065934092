import $api from '@/api'
import Vue from 'vue'

const state = null

const mutations = {
    SET_QINIU_TOKEN: (state, token) => {
        Vue.set(state, "token", token)
    },
}

const actions = {
    requestQiniuToken ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.qiniuToken().then(res => {
                if (res.state == "ok") {
                    commit('SET_QINIU_TOKEN', res.msg)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
