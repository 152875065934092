import $storage from '@/storage'

const state = $storage.getRecentList

const mutations = {
    ADD_RECENT_LIST: (state, item) => {
        const index = state.findIndex(ele => ele.id == item.id)
        if (index == -1) { // 不存在,添加
            state.unshift(item)
        }
        if (state.length > 10) {
            state.pop()
        }
        $storage.setRecentList(state)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
