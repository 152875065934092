<template>
  <v-skeleton-loader v-if="!item.id" type="image" height="186"></v-skeleton-loader>
  <router-link v-else :to="`/category/${item.id}`" class="rounded pa-2 pa-md-4 border text-center d-block text-reset">
    <img :src="item.iconUrl" :alt="item.name" class="img-fluid" />
    <div class="fs-13 opacity-80 text-truncate d-none d-md-block mt-3">
      {{ item.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: [Object, Number, String]
    },
    // 骨架屏高度
    skHeight: {
      type: [String, Number],
      default: 186
    },
  }
}
</script>

<style>
</style>