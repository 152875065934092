import $api from '@/api'

const state = []

const mutations = {
    SET_ADDRESS_LIST: (state, list) => {
        state.length = 0
        state.push(...list)
    },
    // EDIT_ADDRESS_LIST: (state, item) => {
    //     const index = state.findIndex(ele => ele.id == item.id)
    //     if (index != -1) {
    //         state.splice(index, 1)
    //     } else {
    //         state.push(item)
    //     }
    // },
}

const actions = {
    requestAddressList ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.getShippingInfo().then(res => {
                if (res.state == "ok") {
                    commit('SET_ADDRESS_LIST', res.msg)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
