import $api from '@/api'
import Vue from 'vue'

const state = {}

const mutations = {
    SET_SYSTEM_INFO: (state, msg) => {
        Object.keys(msg).forEach(key => {
            Vue.set(state, key, msg[key])
        });
    },
}

const actions = {
    requestSystemInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.getSysInfo().then(res => {
                if (res.state == "ok") {
                    commit('SET_SYSTEM_INFO', res.msg)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
