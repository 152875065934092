const UserInfokey = 'User-Info'
const SelectLangKey = 'select-lang'
const RecentKey = 'recent-views'
const SearchKey = 'search-list'

export default {
    getUserInfo () {
        return localStorage.getItem(UserInfokey) ? JSON.parse(localStorage.getItem(UserInfokey)) : null
    },
    setUserInfo (info) {
        if (info) {
            localStorage.setItem(UserInfokey, JSON.stringify(info))
        }
    },
    removeUserInfo () {
        localStorage.removeItem(UserInfokey)
    },
    getSelectLang: localStorage.getItem(SelectLangKey) ?? "en_US",
    setSelectLang (lang) {
        lang ? localStorage.setItem(SelectLangKey, lang) : localStorage.removeItem(SelectLangKey)
    },
    getRecentList: JSON.parse(localStorage.getItem(RecentKey) ?? "[]"),
    setRecentList (list) {
        localStorage.setItem(RecentKey, JSON.stringify(list))
    },
    getSearchList: JSON.parse(localStorage.getItem(SearchKey) ?? '["phone"]'),
    setSearchList (list) {
        localStorage.setItem(SearchKey, JSON.stringify(list))
    },
}
