<template>
  <div class="product-thumb">
    <swiper :options="swiperOptionTop" class="gallery-top  mb-4 border rounded" ref="swiperTop">
      <swiper-slide v-for="(item,index) in dataList" :key="index">
        <img :src="item">
      </swiper-slide>
    </swiper>
    <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
      <swiper-slide class="border" v-for="(item,index) in dataList" :key="index">
        <img :src="item">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [
        "https://shop-result.s3.ap-southeast-1.amazonaws.com/shop/u-like/product/14653b567ad32dfc6e30d2b4e08e6bfe.jpg",
        "https://shop-result.s3.ap-southeast-1.amazonaws.com/shop/u-like/product/83a81ef2add9145451e65fef9083aa0e.jpg",
        "https://shop-result.s3.ap-southeast-1.amazonaws.com/shop/u-like/product/711fcdd99ae2b02f7b821945b0414aa8.jpg",
        "https://shop-result.s3.ap-southeast-1.amazonaws.com/shop/u-like/product/a921b45c653f0c9c78262d5ef0631654.jpg",
        "https://shop-result.s3.ap-southeast-1.amazonaws.com/shop/u-like/product/72402b4489017355cb3b76c5292fb14f.jpg",
      ]
    }
  },
  data () {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 4,
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 4,
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: "4",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        slideActiveClass: 'my-slide-active',
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  }
}
</script>

<style scoped>
.border.my-slide-active {
  border: 2px solid orange !important;
  border-radius: 4px;
}
.product-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery-top {
  height: 381px;
}
.gallery-thumbs {
  height: 100px;
}
</style>