import Vue from 'vue';
import Vuetify from 'vuetify';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTelInput from 'vue-tel-input';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import 'swiper/css/swiper.min.css'
import 'vuetify/dist/vuetify.min.css'
import 'vue-tel-input/dist/vue-tel-input.css';
import '@/assets/style.css'

Vue.use(Vuetify);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTelInput);

export default new Vuetify({
});
