import $api from '@/api'

const state = [
    {
        position: 1,
        slidesView: 1,
        products: []
    },
    {
        position: 2,
        slidesView: 1,
        products: []
    },
    {
        position: 3,
        slidesView: 1,
        products: []
    },
    {
        position: 4,
        slidesView: 1,
        products: []
    },
    {
        position: 5,
        slidesView: 3,
        products: []
    },
    {
        position: 6,
        slidesView: 2,
        products: []
    },
    {
        position: 7,
        slidesView: 1,
        products: []
    },
    {
        position: 8,
        slidesView: 3,
        productType: 1,
        products: []
    },
    {
        position: 9,
        slidesView: 2,
        products: []
    },
    {
        position: 10,
        slidesView: 4,
        products: []
    },
]

const mutations = {
    SET_AD_LIST: (state, list) => {
        // state.length = 0
        // state.push(...list)
        state.forEach(ele => {
            const arr = list.filter(e => e.position.split("-")[0] == ele.position)
            ele.products = arr.map(e => {
                return {
                    "price": e.price ?? 0,
                    "name": e.alt,
                    "id": e.link,
                    "iconUrl": e.src
                }
            })
        });
    },
}

const actions = {
    requestAllAdList ({ commit }) {
        return new Promise((resolve) => {
            $api.advertHome().then(res => {
                if (res.state == "ok") {
                    commit('SET_AD_LIST', res.msg)
                }
                resolve(res)
            }).catch(() => {
                resolve(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
