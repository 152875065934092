<template>
  <section class="promise">
    <div class="container">
      <div class="row">
        <div class="d-flex flex-column align-center col-sm-6 col-md-6 col-lg-3 col-6"><img src="@/assets/img/hearts.b4c77e62.svg" alt="icon" width="40px" height="40px"><span class="promise__text">{{$t('100_authentic')}}</span></div>
        <div class="d-flex flex-column align-center col-sm-6 col-md-6 col-lg-3 col-6"><img src="@/assets/img/back.f4fcbbff.svg" alt="icon" width="40px" height="40px"><span class="promise__text">{{$t('7day_returns')}}</span></div>
        <div class="d-flex flex-column align-center col-sm-6 col-md-6 col-lg-3 col-6"><img src="@/assets/img/car.27248849.svg" alt="icon" width="40px" height="40px"><span class="promise__text">{{$t('shipping_discounts')}}</span></div>
        <div class="d-flex flex-column align-center col-sm-6 col-md-6 col-lg-3 col-6"><img src="@/assets/img/card.50ae72a7.svg" alt="icon" width="40px" height="40px"><span class="promise__text">{{$t('safe_payment')}}</span></div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
};
</script>


<style scoped>
.promise__text {
  font-size: 12px;
  font-weight: 700;
  opacity: 0.7;
}
</style>