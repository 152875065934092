import $api from '@/api'
import Vue from 'vue'

const state = {
    ts: 0
}

const mutations = {
    SET_TIMES_LIST: (state, ts) => {
        Vue.set(state, "ts", ts)
    },
}

const actions = {
    requestServiceTime ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.systemGetDate().then(res => {
                if (res.state == "ok") {
                    const serverTs = Number(res.msg.timestamp)
                    const localeTs = new Date().getTime()
                    // console.log("服务时间：", serverTs);
                    // console.log("本地时间：", localeTs);
                    // console.log("时间差", serverTs - localeTs);
                    commit('SET_TIMES_LIST', serverTs - localeTs)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
