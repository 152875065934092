import axios from 'axios'
import $vm from '@/main'
import { encrypt } from '@/utils/crypto'
const Base64 = require("js-base64").Base64
import $storage from "@/storage";

// 创建axios实例
const instance = axios.create({
    timeout: 150000,
    baseURL: process.env.NODE_ENV === 'development' ? '/apis' : process.env.VUE_APP_BASE_URL
})

// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        const userInfo = $storage.getUserInfo()
        if (userInfo?.token) {
            const timestamp = new Date().getTime() + $vm.$store.state.diff.ts
            config.headers.token = userInfo.token
            config.headers.timestamp = timestamp
            config.headers.sign = encrypt(`${userInfo.id}${timestamp}` + config.url + Base64.decode('SGVuZ0ppYTUwMExvdHRlcg=='))
        }
        config.headers.locale = $storage.getSelectLang
        return config
    },
    error => {
        return Promise.resolve(error)
    }
)

// 响应拦截器
instance.interceptors.response.use(
    (res) => {
        if (res.status === 200) {
            if (res.data.state && res.data.code) {
                if (res.data.code === 50401 || res.data.code === 50402 || res.data.code === 50403 || res.data.code === 601 || res.data.code === 602 || res.data.code === 603 || res.data.code === 604) {
                    if (res.data.code === 603) {
                        $vm.$store.dispatch("diff/requestServiceTime")
                    }
                    $vm.$showSnackbar.show(res.data.msg)
                    $vm.$store.commit("user/REMOVE_USER_INFO")
                    if ($vm.$route.name != "login") {
                        $vm.$router.replace("/user/login")
                    }
                } else if (res.data.code === 50404) { // 账户异常
                    $vm.$showSnackbar.error(res.data.msg)
                }
            }
        }
        return Promise.resolve(res.data);
    },
    error => {
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            return Promise.resolve({ state: "fail", msg: 'timeout' })
        } else {
            return Promise.resolve({ state: "fail", msg: error.message })
        }
    }
)

export default instance
