import $api from '@/api'
import Vue from 'vue'

const state = []

const mutations = {
    SET_CART_LIST: (state, list) => {
        state.length = 0
        state.push(...list)
    },
    EDIT_CART_LIST: (state, { item, quantity }) => {
        const index = state.findIndex(ele => ele.cartId == item.cartId)
        if (index == -1) { // 不存在,添加
            // console.log("不存在,添加,quantity: ", quantity)
            state.push(Object.assign(item, { quantity, isSelect: true }))
        } else { // 已存在，手数加减，其中手数小于0时移除
            // console.log("已存在，更新手数,quantity: ", quantity)
            state[index].quantity += quantity
            Vue.set(state, index, state[index])
            if (state[index].quantity < 1) {
                state.splice(index, 1)
            }
        }
    },
}

const actions = {
    requestCartList ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.cartList().then(res => {
                if (res.state == "ok") {
                    res.msg.forEach(ele => {
                        ele.isSelect = true
                    });
                    commit('SET_CART_LIST', res.msg)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
    requestEditCart ({ commit }, { item, quantity }) {
        return new Promise((resolve) => {
            $api.cartAdd(item.id, quantity < 0 ? `N${quantity * -1}` : quantity).then(res => {
                if (res.state == "ok" && res.msg) {
                    commit('EDIT_CART_LIST', { item, quantity })
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch(() => {
                resolve(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
