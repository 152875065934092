import $api from '@/api'

const state = []

const mutations = {
    SET_FOLLOW_LIST: (state, list) => {
        state.length = 0
        state.push(...list)
    },
    EDIT_FOLLOW_LIST: (state, item) => {
        const index = state.findIndex(ele => ele.id == item.id)
        if (index != -1) {
            state.splice(index, 1)
        } else {
            state.push(item)
        }
    },
}

const actions = {
    requestFollowList ({ commit }) {
        return new Promise((resolve) => {
            $api.followList({ p: 1, r: 10 }).then(res => {
                if (res.state == "ok") {
                    commit('SET_FOLLOW_LIST', res.msg.list)
                }
                resolve(true)
            }).catch(() => {
                resolve(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
