<template>
  <v-snackbar v-model="visibility" :color="color" timeout="3000" style="z-index: 999999!important;">
    {{ text }}
    <v-btn slot="action" icon small fab @click="visibility = false">
      <i class="las la-times la-2x"></i>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data () {
    return {
      visibility: false,
      text: "",
      color: null,
    }
  }
}
</script>

<style>
</style>