<template>

  <!-- 广告 -->
  <div v-if="isAd" class="container py-0 px-0 px-md-3">
    <swiper class="mb-4" :options="carouselOption">
      <swiper-slide v-for="(item,index) in dataList" :key="index">
        <slot :item="item"></slot>
      </swiper-slide>
    </swiper>
  </div>
  <!-- :class="{'pe-0 pe-md-3 ps-3':subTitle}" -->
  <div v-else class="mb-5">
    <div :class="containerClass">
      <!-- 标题 -->
      <div v-if="subTitle" class="d-flex justify-space-between align-center mb-4 pe-3 pe-md-0">
        <h2>{{ title }}</h2>
        <router-link :to="subLink" class="py-2 primary--text lh-1 d-inline-block">
          {{subTitle}} <i class="las la-angle-right"></i>
        </router-link>
      </div>
      <h2 :class="titleClass" v-else>{{ title }}</h2>
      <!-- 轮播 -->
      <div>
        <swiper :options="carouselOption">
          <swiper-slide v-for="(item,index) in dataList" :key="index">
            <slot :item="item"></slot>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    "title": { type: String },
    "titleClass": { type: String, default: "mb-4" },
    "containerClass": { type: String },
    "subTitle": { type: String },
    "subLink": { type: String },
    "options": { type: Object },
    "dataList": { type: [Array, Number] },
    "isRow2": { type: Boolean, default: false },
    "autoplay": { type: Boolean, default: false },
    "slidesView": { type: [Number, String], default: 6 },
    "isAd": { type: Boolean, default: false },
  },
  computed: {
    carouselOption () {
      // 1 []
      // 2 [1, 1, 2, 2, 2] 0 0
      // 3 [1, 1, 2, 3, 3] 0 0
      // 3 [1, 2, 2, 3, 3] 两行row2
      // 4 []
      // 5 [2, 3, 4, 5, 5]
      // 6 [2, 4, 4, 5, 6]
      // 7 []
      // 8 [4.5, 5, 6, 7, 8]

      var arr
      var space0 = 12
      var space599 = 16

      switch (Number(this.slidesView)) {
        case 2:
          arr = [1, 1, 2, 2, 2]
          space0 = 0
          space599 = 0
          break;
        case 3:
          if (this.isRow2) {
            arr = [1, 2, 2, 3, 3]
          } else {
            arr = [1, 1, 2, 3, 3]
            space0 = 0
            space599 = 0
          }
          break;
        case 4:
          if (this.isRow2) {
            arr = [1, 2, 2, 3, 4]
            space599 = 12
          } else {
            arr = [1, 2, 3, 4, 4]
            space0 = 0
            space599 = 0
          }
          break;
        case 5:
          arr = [2, 3, 4, 5, 5]
          break;
        case 6:
          arr = [2, 4, 4, 5, 6]
          break;
        case 7:
          arr = [2, 3, 5, 6, 7]
          break;
        case 8:
          arr = [4.5, 5, 6, 7, 8]
          break;
        default:

          break;
      }


      var option = {
        slidesPerView: this.slidesView,
        spaceBetween: this.slidesView == 1 ? 0 : 20,
        breakpoints: this.slidesView == 1 ? null : {
          0: {
            slidesPerView: arr[0],
            spaceBetween: space0,
          },
          599: {
            slidesPerView: arr[1],
            spaceBetween: space599,
          },
          960: {
            slidesPerView: arr[2],
            spaceBetween: 20,
          },
          1264: {
            slidesPerView: arr[3],
            spaceBetween: 20,
          },
          1904: {
            slidesPerView: arr[4],
            spaceBetween: 20,
          }
        }
      }

      if (this.autoplay) {
        option.autoplay = {
          delay: 2500,
          disableOnInteraction: !1
        }
      }
      if (this.isRow2) {
        option.slidesPerColumn = 2
        option.slidesPerColumnFill = "row"
        Object.keys(option.breakpoints).forEach(key => {
          option.breakpoints[key].slidesPerColumn = 2
          option.breakpoints[key].slidesPerColumnFill = "row"
        });
      }

      return option
    }
  },

  created () {
    // if (this.isRow2) {
    //   console.log("双行", this.isRow2, typeof this.isRow2);
    // } else {
    //   console.log("单行", this.isRow2, typeof this.isRow2);
    // }
  }
};
</script>

<style scoped>
h2 {
  font-size: 16px;
}

@media (min-width: 960px) {
  h2 {
    font-size: 24px;
  }
}
</style>
