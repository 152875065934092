<template>
  <v-menu z-index="99999" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" :class="{'lang-border' : border}">
        <span class="opacity-60">{{crtLanguageName}}</span>
        <i class="las la-angle-down ms-1 fs-12"></i>
      </span>
    </template>
    <v-list class="fs-13">
      <v-list-item v-for="(item, index) in langList" :key="index" @click="changeLoCale(item.languageCode)">
        <img :src="item.iconUrl" class="me-1 h-10px">
        <v-list-item-title class="fs-13 opacity-60">{{ item.languageName }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import $storage from "@/storage";

export default {
  props: {
    border: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    langList () {
      return this.$store.state.lang
    },
    crtLanguageName () {
      return this.langList.find((n) => n.languageCode == $storage.getSelectLang)?.languageName;
    },
  },
  methods: {
    changeLoCale (locale) {
      $storage.setSelectLang(locale);
      window.location.reload();
    },
  },
  created () {
    this.$store.dispatch("lang/requestLangList")
  }
}
</script>

<style scoped>
.lang-border {
  display: inline-block !important;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 12px;
}
</style>