<template>
  <!-- 骨架屏 -->
  <div v-if="!item.id">
    <v-skeleton-loader type="image" :height="skHeight"></v-skeleton-loader>
  </div>
  <!-- 上下不带产品 -->
  <div v-else-if="type == 2" class="shop-box-two">
    <div class="border rounded overflow-hidden">
      <div class="row no-gutters align-center">
        <div class="minw-0 position-relative col col-12">
          <div class="lh-0 position-relative">
            <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset d-block">
              <v-img :src="item.bannerUrl" lazy-src="/uploads/all/img_placeholder.png" :alt="item.storeName" class="img-fit h-150px"></v-img>
            </router-link>
            <div class="absolute-bottom-left w-100 grey darken-3 white--text d-flex align-center py-2 fs-12 px-3">
              <span class="me-1 fw-600"> {{item.rating}} </span>
              <v-rating class="lh-1-2" readonly :value="item.rating" :half-increments="true" full-icon="la-star" empty-icon="la-star" half-icon="la-star half" size="11" background-color></v-rating>
            </div>
          </div>
          <div class="text-center fs-12 pa-5 position-relative">
            <div class="">
              <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset">
                <h4 class="fs-21 text-truncate mb-2"> {{item.storeName}} </h4>
              </router-link>
            </div>
            <div class="d-flex flex-column mt-5">
              <v-btn :to="`/shop/${item.id}`" target="_blank" class="mt-2 bg-soft-primary border border-primary" elevation="0" small>
                {{$t('visit_store')}}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 左右带产品 -->
  <div v-else-if="type == 3" class="shop-box-three">
    <div class="border rounded overflow-hidden">
      <div class="row no-gutters align-center">
        <div class="minw-0 position-relative col-sm-6 col-12">
          <div class="text-center fs-12 pa-4">
            <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset">
              <v-img :src="item.logoUrl" lazy-src="/uploads/all/img_placeholder.png" :alt="item.storeName" class="border rounded-circle shadow-2xl border-2 size-90px mb-2"></v-img>
            </router-link>
            <div class="">
              <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset">
                <h4 class="fs-21 text-truncate-2 lh-1-4 h-60px mb-2"> {{item.storeName}} </h4>
              </router-link>
              <div class="d-flex fs-12 my-2 justify-center"><span> {{item.rating}} </span>
                <v-rating class="lh-1-4" readonly :value="item.rating" :half-increments="true" full-icon="la-star" empty-icon="la-star" half-icon="la-star half" size="11" background-color></v-rating>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <v-btn :to="`/shop/${item.id}`" target="_blank" class="mt-2 bg-soft-primary border border-primary" elevation="0" small>
                {{$t('visit_store')}}
              </v-btn>
            </div>
          </div>
        </div>
        <div class="minw-0 col-sm-6 col-12">
          <div class="">
            <div class="row gutters-10 no-gutters">
              <div v-for="(proEle, index) in item.products" :key="index" class="col col-12">
                <ProductBox class="my-4 me-4" :item="proEle" :type="2"></ProductBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 上下带产品 -->
  <div v-else-if="type == 4" class="shop-box-four">
    <div class="border rounded overflow-hidden">
      <div class="row no-gutters align-center">
        <div class="minw-0 position-relative col col-12">
          <div class="lh-0 position-relative">
            <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset d-block">
              <v-img :src="item.bannerUrl" lazy-src="/uploads/all/img_placeholder.png" :alt="item.storeName" class="img-fit h-150px"></v-img>
            </router-link>
          </div>
          <div class="text-center fs-12 absolute-left-center align-center d-flex ms-4">
            <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset">
              <v-img :src="item.logoUrl" lazy-src="/uploads/all/img_placeholder.png" :alt="item.storeName" class="border rounded-circle shadow-2xl border-2 size-90px"></v-img>
            </router-link>
            <div class="ms-3 pt-1 pb-2 px-3 text-start position-relative">
              <div class="white absolute-full opacity-80" style="z-index: -1;"></div>
              <router-link :to="`/shop/${item.id}`" target="_blank" class="text-reset">
                <h4 class="fs-21 text-truncate"> {{item.storeName}} </h4>
              </router-link>
              <div class="d-flex fs-12"><span class="me-2"> {{item.rating}} </span>
                <v-rating class="lh-1-4" readonly :value="item.rating" :half-increments="true" full-icon="la-star" empty-icon="la-star" half-icon="la-star half" size="11" background-color></v-rating>
              </div>
            </div>
          </div>
        </div>
        <div class="minw-0 col col-12">
          <div class="pa-4">
            <div class="row gutters-10">
              <div v-for="(proEle, index) in item.products" :key="index" class="col-sm-4 col-12">
                <ProductBox :item="proEle" type="2"></ProductBox>
              </div>
            </div>
            <div class="text-end mt-3">
              <v-btn :to="`/shop/${item.id}`" target="_blank" class="primary--text transparent" elevation="0" small>
                <span>{{$t('visit_store')}}</span><i class="las la-arrow-right"></i>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductBox from './ProductBox.vue';

export default {
  props: {
    item: {
      type: [Object, String, Number],
    },
    type: {
      type: [Number, String],
      default: 2
    },
    // 骨架屏高度
    skHeight: {
      type: [String, Number],
      default: 186
    },
  },
  components: {
    ProductBox
  }
};
</script>
  
<style></style>
  