<template>
  <div class="page-lead">

    <div class="head-wrap">
      <div class="container head-content">
        <div class="fs-20 mb-6">了解更多关于 Alibaba.com 的信息</div>
        <h2 class="fs-30 fw-600 mb-8">领先的全球贸易 B2B 电子商务平台</h2>
        <div class="col-md-8 col-sm-8" style="padding:0;">
          <v-autocomplete outlined background-color="#fff" filled rounded placeholder="搜索">
            <!-- <template slot="append">
            <v-btn rounded color="orange" text-color="#fff">搜索</v-btn>
          </template> -->
          </v-autocomplete>
        </div>
        <div>
          <div class="mr-6 mb-2">常搜内容: </div>
          <v-btn class="mr-4 mb-2" v-for="(item, index) in 6" :key="index" outlined rounded color="#fff" small>电压力锅</v-btn>
        </div>
      </div>
    </div>

    <div class="nav-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3" v-for="(item, index) in 4" :key="index">
            <div class="nav-cude">
              <img src="https://s.alicdn.com/@img/imgextra/i3/O1CN01IqRqQr1UYmu3AOqmF_!!6000000002530-2-tps-96-96.png">
              <div class="fs-24 mt-1 mb-8">海量产品供您选择</div>
              <div class="mb-8">从全球海量资源中找到适合您的产品与供应商。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="industry-category-wrapper">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12" md="8" class="fs-44">探索为您的企业量身定制的海量优惠</v-col>
          <v-col cols="12" sm="12" md="4" class="category-number">
            <div class="number-item"><span>2+ 亿</span>
              <p>产品</p>
            </div>
            <div class="number-item"><span>20+ 万</span>
              <p>供应商</p>
            </div>
            <div class="number-item"><span>5900</span>
              <p>产品类目</p>
            </div>
            <div class="number-item"><span>200+</span>
              <p>国家与地区</p>
            </div>
          </v-col>
        </v-row>
        <SwiperContainer class="industry-list" :dataList="categoryList" slidesView="7" autoplay :isRow2="true">
          <template slot-scope="{ item }">
            <router-link :title="item.name" :to="`/category/${item.id}`">
              <div class="category-item">
                <img :src="item.iconUrl">
                <div class="category-name"><span>{{item.name}}</span></div>
              </div>
            </router-link>
          </template>
          <!-- <CategoriesWidget slot-scope="{ item }" :item="item" skHeight="186"></CategoriesWidget> -->
        </SwiperContainer>
      </div>
    </div>
    <div class="factory-floor-entrance-container">
      <div class="container">
        <div class="fs-44 fw-600 mt-16">采购工厂直供商品</div>
        <v-row class="mt-10 mb-16">
          <v-col cols="12" md="6" lg="4">
            <div class="entrance-card-item entrance-card-item1">
              <div class="entrance-card-item-title fs-20">一起实时云探厂吧</div>
              <div class="entrance-card-item-link-text fs-16 text-decoration-underline">观看直播</div>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <div class="entrance-card-item entrance-card-item2">
              <div class="entrance-card-item-title fs-20">结识头部制造商</div>
              <div class="entrance-card-item-link-text fs-16 text-decoration-underline">查看详细信息</div>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <div class="entrance-card-item entrance-card-item3">
              <div class="entrance-card-item-title fs-20">获取样品</div>
              <div class="entrance-card-item-link-text fs-16 text-decoration-underline">查看详细信息</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="security-floor">
      <div class="container">
        <div class="fs-44 mb-16">从生产品质到采购保障，让您的交易更放心</div>
        <v-row>
          <v-col cols="12" md="6">
            <div class="security-floor_card">
              <div>确保生产质量</div>
              <img src="https://s.alicdn.com/@img/imgextra/i3/O1CN01cnsiSd1sFb5vxUBwd_!!6000000005737-2-tps-1200-210.png">
              <div class="mb-10">结识各类第三方认证的优质供应商。认准“认证"标志，为您的企业找到值得信赖的采购伙伴。</div>
              <div class="text-decoration-underline c-pointer">了解更多</div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="security-floor_card">
              <div>保护您的交易，认准</div>
              <img src="https://s.alicdn.com/@img/imgextra/i1/O1CN01I0ebSF1UCntpAivUU_!!6000000002482-2-tps-1200-210.png">
              <div class="mb-10">当您在 Alibaba.com 下单和付款时，您将获得多种安全支付选项、产品与货运保障以及任何与采购相关问题的调解支持，您完全可以放心采购。</div>
              <div class="text-decoration-underline c-pointer">了解更多</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="full-link">
      <div class="full-link_box container">
        <div class="full-link_title fs-44 fw-600 mt-16 col-md-8">从搜索到履约，一站式完成，全面简化您的订购流程</div>
        <v-row class="mt-16 mb-16">
          <v-col cols="12" md="6" lg="6">
            <ul class="full-link_item_wrapper">
              <li v-for="(item, index) in linkDatas" :key="index" class="full-link_item" :class="{'full-link_item_enter':activeIndex == index}" @mouseenter="onmouseenter(index)">
                <div class="full-link_icon_wrapper">
                  <img class="full-link_icon" :src="activeIndex == index ? item.activeIcon :item.icon" alt="icon">
                </div>
                <div class="full-link_content">
                  <div class="full-link_item_title" :title="item.title">{{item.title}}</div>
                  <div class="full-link_item_desc" v-html="item.desction"></div>
                </div>
              </li>
              <div class="full-link_item_tail"></div>
            </ul>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <img class="full-link_item_img" style="max-width: 100%;" :src="linkDatas[activeIndex].itemImg">
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="dob-floor">
      <div class="container">
        <div class="dob-floor_title">获取适合您的企业体量的个性化折扣、服务与工具</div>
        <div class="dob-floor_desc">无论您是需要基础条件来开启采购的小型企业还是正在为更复杂的订单寻找工具和解决方案的成熟企业，都可以从 Alibaba.com 会员计划提供的免费精选权益中获得成长的助力。</div>
        <router-link class="dob-floor_learn_more" to="/">了解更多</router-link>
        <div class="dob-floor_rotating_wrapper">
          <swiper :options="rateOptions" class="dob-floor_slider_wrapper">
            <swiper-slide class="slide-item">
              <v-row class="dob-floor_slider_item">
                <v-col cols="12" md="6" style="display: flex; align-items: center;">
                  <img alt="image" class="bimg dob-floor_slider_img" src="//s.alicdn.com/@img/imgextra/i4/O1CN01XrvnBG1YDUxJ0l5Bh_!!6000000003025-2-tps-352-352.png_350x350.jpg">
                  <div>
                    <div class="dob-floor_slider_name">Eva Jane</div>
                    <div class="dob-floor_slider_job">Eva Jane Beauty 创始人</div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="dob-floor_slider_reviews">“作为一位深耕美妆行业的企业家，我一直致力于研发原创产品。Alibaba.com 已成为我美妆事业的可靠伙伴。”</div>
                </v-col>
              </v-row>
            </swiper-slide>
            <swiper-slide class="slide-item">
              <v-row class="dob-floor_slider_item">
                <v-col cols="12" md="6" style="display: flex; align-items: center;">
                  <img alt="image" class="bimg dob-floor_slider_img" src="https://s.alicdn.com/@img/imgextra/i3/O1CN01wllRR11a9Uiq6syoP_!!6000000003287-2-tps-352-352.png_350x350.jpg">
                  <div>
                    <div class="dob-floor_slider_name">Dr. Sayed Ibrihim</div>
                    <div class="dob-floor_slider_job">SprinJene 创始人</div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="dob-floor_slider_reviews">“自从我使用了 Alibaba.com，我才意识到我能在全球找到如此之多的供应商与资源，这太让我惊讶了。”</div>
                </v-col>
              </v-row>
            </swiper-slide>
          </swiper>
          <div slot="button-prev" class="swiper-button-prev">
          </div>
          <div slot="button-next" class="swiper-button-next">
          </div>
        </div>
      </div>
    </div>
    <div class="login-guide">
      <div class="container">
        <div class="fs-44">准备好开始了吗？</div>
        <div class="mt-5 fs-20">立即注册，探索可靠供应商的海量精品！</div>
        <div class="mt-8">
          <v-btn class="white--text px-15" x-large rounded color="#f60">注册</v-btn>
        </div>
      </div>
    </div>
    <div class="vision-floor">
      <div class="container">
        <div class="vision-floor_text_wrapper text-center">
          <div class="fs-44 mt-16 fw-600" style="color: #51200b;">通过全球贸易为企业赋能</div>
          <div class="mt-5 fs-20">Alibaba.com 为全球中小企业提供一站式 B2B 交易解决方案，使他们能够通过数字贸易转型，抓住机遇，加速国际业务增长。</div>
        </div>
        <div class="vision-floor_graphic_wrapper py-10">
          <v-row>
            <v-col cols="12" md="6">
              <div class="vision-floor_graphic vision-floor_graphic_1">
                <div class="vision-floor_graphic_desc">让天下没有难做的生意。</div>
                <div class="vision-floor_graphic_title">我们的使命</div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="vision-floor_graphic vision-floor_graphic_2">
                <div class="vision-floor_graphic_desc">我们的团队遍及全球。</div>
                <div class="vision-floor_graphic_title">我们的足迹</div>
                <div class="vision-floor_names">Hangzhou, China<br>Paris, France<br>Munich, Germany<br>Tokyo, Japan<br>Seoul, Korea<br>London, UK<br>New York, US<br>...<br>也在世界各地。 </div>
              </div>
              <div class="vision-floor_graphic vision-floor_graphic_3">
                <div class="vision-floor_graphic_desc">负责任的技术。 可持续的未来</div>
                <div class="vision-floor_graphic_title">我们的 ESG 承诺</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="countries-v4">
      <div class="container">
        <div class="countries-title">
          <div class="fs-28 fw-600">按国家或地区查找供应商</div>
          <div class="right-wrapper">
            <div class="more-text">查看详细信息</div>
            <img src="//s.alicdn.com/@img/imgextra/i1/O1CN01goKJ191eLBFDKRvVA_!!6000000003854-2-tps-54-45.png" class="more-icon" alt="">
          </div>
        </div>
        <SwiperContainer :dataList="langList" slidesView="8" autoplay>
          <router-link slot-scope="{ item }" class="home-country-bg" to="/">
            <div class="home-country-item">
              <div class="country-item-image"><img :src="item.iconUrl"></div>
              <div class="country-item-text">{{item.languageName}}</div>
            </div>
          </router-link>
        </SwiperContainer>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import SwiperContainer from "@/components/SwiperContainer";
// import CategoriesWidget from "@/components/widget/Categories";
import AppFooter from "@/components/AppFooter.vue";

export default {
  data () {
    return {
      rateOptions: {
        loop: true,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }
      },
      linkDatas: [
        {
          title: "搜索匹配项",
          desction: "搜索并筛选海量资源，找到匹配您企业的产品与供应商。",
          icon: "//s.alicdn.com/@img/imgextra/i4/O1CN01mkYwO01N9PeKLQWYq_!!6000000001527-2-tps-96-96.png",
          activeIcon: "https://s.alicdn.com/@img/imgextra/i4/O1CN015t37vw1Dd37SPrbRN_!!6000000000238-2-tps-96-96.png",
          itemImg: "https://s.alicdn.com/@img/imgextra/i1/O1CN01KrWFW11fg52xUQzdc_!!6000000004035-0-tps-1380-1060.jpg"
        },
        {
          title: "锁定正确结果",
          desction: "通过验真检验与数字化采购工具，轻松、高效地评估产品质量与供应商能力。",
          icon: "//s.alicdn.com/@img/imgextra/i4/O1CN015lgvwy1md3g5nlS9C_!!6000000004976-2-tps-96-96.png",
          activeIcon: "https://s.alicdn.com/@img/imgextra/i4/O1CN01dVw4bH1TrHHEsocfH_!!6000000002435-2-tps-96-96.png",
          itemImg: "https://s.alicdn.com/@img/imgextra/i2/O1CN0168f1F61TkrjFojnmE_!!6000000002421-2-tps-1380-1060.png"
        },
        {
          title: "放心支付",
          desction: "支持 20 多种安全支付方式与 20 多种货币",
          icon: "//s.alicdn.com/@img/imgextra/i3/O1CN01HZd7ME1fEbDXenc3M_!!6000000003975-2-tps-96-96.png",
          activeIcon: "https://s.alicdn.com/@img/imgextra/i3/O1CN01RWBwcK1op5JQTJsYT_!!6000000005273-2-tps-96-96.png",
          itemImg: "https://s.alicdn.com/@img/imgextra/i1/O1CN01XW2muo1PFU87b4zQ5_!!6000000001811-2-tps-1380-1060.png"
        },
        {
          title: "透明履约",
          desction: "来<a href='https://www.baidu.com' target='_blank'><span>Alibaba.com 物流平台</span></a>为您的物流需求定制个性化解决方案，实时跟踪 220 个国家和地区的 26000 多条航线进行，所有功能均由 Alibaba.com 物流提供技术支持。",
          icon: "//s.alicdn.com/@img/imgextra/i3/O1CN01Q2EI0L1iqYtRZh1gI_!!6000000004464-2-tps-96-96.png",
          activeIcon: "https://s.alicdn.com/@img/imgextra/i4/O1CN01qIH9m81oWlSvgepj9_!!6000000005233-2-tps-96-96.png",
          itemImg: "https://s.alicdn.com/@img/imgextra/i2/O1CN01spEIDZ1TPnVsDakHo_!!6000000002375-2-tps-1380-1060.png"
        },
        {
          title: "轻松管理",
          desction: "查看订单状态，管理供应商，跟踪付款和发货进展，并通过我的阿里巴巴联系售后支持。",
          icon: "//s.alicdn.com/@img/imgextra/i4/O1CN01bw2aFV1obnp2vp772_!!6000000005244-2-tps-96-96.png",
          activeIcon: "https://s.alicdn.com/@img/imgextra/i4/O1CN012nUsin1exeYsphwoy_!!6000000003938-2-tps-96-96.png",
          itemImg: "https://s.alicdn.com/@img/imgextra/i1/O1CN01HrdHbz2511UJNFOxq_!!6000000007465-2-tps-1380-1060.png"
        },
      ],
      activeIndex: 0,
    }
  },
  computed: {
    categoryList: (vm) =>
      vm.$store.state.category.length ? vm.$store.state.category : 8,
    langList () {
      return this.$store.state.lang
    },
  },
  components: {
    SwiperContainer,
    AppFooter,
    // CategoriesWidget
  },
  methods: {
    onmouseenter (index) {
      this.activeIndex = index
    }
  },
  created () {
    this.$store.dispatch("category/requestCategoryList");
    this.$store.dispatch("lang/requestLangList")
  }
}
</script>

<style lang="less" scoped>
.page-lead {
  .dob-floor {
    background-color: #f7f2f0;
    .dob-floor_title {
      color: #51200b;
      font-size: 44px;
      font-weight: 600;
      padding-top: 120px;
      text-align: center;
    }

    .dob-floor_desc {
      color: #666;
    }

    .dob-floor_desc,
    .dob-floor_learn_more {
      font-size: 20px;
      margin: 20px auto 0;
      text-align: center;
    }
    .dob-floor_learn_more {
      display: block;
      color: #222;
      font-weight: 600;
      text-decoration-line: underline;
    }

    .dob-floor_rotating_wrapper {
      background: url(//s.alicdn.com/@img/imgextra/i1/O1CN01nO5wJp24Fqgne6DLr_!!6000000007362-2-tps-1812-660.png)
        no-repeat 100% 50%;
      background-size: 600px 200px;
      background-color: #fff;
      border-radius: 16px;
      min-height: 400px;
      margin: 40px auto 120px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 20px 0;
      .slide-item {
        padding: 0 50px;
        overflow: hidden;
      }
      .dob-floor_slider_item {
        display: flex;
        align-items: center;
        .dob-floor_slider_img {
          height: 150px;
          margin-inline-end: 20px;
          width: 150px;
        }

        .dob-floor_slider_name {
          -webkit-line-clamp: 2;
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
        }
        .dob-floor_slider_job {
          -webkit-line-clamp: 4;
          color: #666;
          font-size: 16px;
          margin-top: 8px;
        }
        .dob-floor_slider_reviews {
          -webkit-line-clamp: 9;
          display: -webkit-box;
          font-size: 24px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .head-wrap {
    color: #fff;
    background: url("//s.alicdn.com/@img/imgextra/i4/O1CN01ustqhi1Tz44lu4arh_!!6000000002452-0-tps-3840-1248.jpg_q60.jpg")
      no-repeat 60%;
    background-color: #111110;
    background-size: cover;
    .head-content {
      min-height: 624px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .nav-wrapper {
    color: #fff;
    background-color: #3a190b;
    padding: 60px 0;
    .nav-cude {
      background-color: hsla(0, 0%, 100%, 0.03);
      border-radius: 16px;
      padding: 40px 20px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .industry-category-wrapper {
    padding: 120px 0;
    .industry-list {
      margin-top: 110px;
      @media (min-width: 960px) {
      }
      .category-item {
        width: 160px;
        height: 160px;
        border: 2px solid #eaeaea;
        border-radius: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      .category-item:hover {
        border: 2px solid #f60;
      }

      .category-item img {
        width: 48px;
        height: 48px;
        margin-bottom: 10px;
      }
      .category-item .category-name {
        color: #222;
      }
    }
    .category-number {
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
      column-gap: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      .number-item {
        padding-left: 16px;
        position: relative;
        span {
          color: #f60;
          font-size: 36px;
          font-weight: 500;
          letter-spacing: -0.73px;
          line-height: 44px;
        }
        p {
          color: #51200b;
          font-size: 20px;
          line-height: 26px;
          margin: 0;
          padding: 0;
        }
        &::before {
          background-color: #ddd;
          border-radius: 2px;
          content: "";
          display: inline-block;
          height: 86%;
          left: 0;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 4px;
        }
      }
    }
  }
  .security-floor {
    background: url(https://s.alicdn.com/@img/imgextra/i1/O1CN01ZU454s273tH4l7azv_!!6000000007742-0-tps-3000-1394.jpg_q60.jpg)
      no-repeat center;
    background-size: cover;
    color: #fff;
    padding: 120px 0;
    > div {
      // padding: 120px 0;
      .security-floor_card {
        height: 100%;
        backdrop-filter: blur(50px);
        background: hsla(0, 0%, 100%, 0.11);
        border-radius: 20px;
        font-size: 16px;
        overflow: hidden;
        padding: 50px;
        position: relative;
        transform: translateZ(0);
        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin: 18px 0 20px;
        }
      }
    }
  }
  .login-guide {
    background: url(https://s.alicdn.com/@img/imgextra/i3/O1CN01FaQ37w1Zb91Cymass_!!6000000003212-0-tps-1920-456.jpg)
      no-repeat center;
    background-size: 100% 100%;
    color: #fff;
    > div {
      min-height: 456px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .vision-floor {
    color: #fff;
    .vision-floor_graphic {
      border-radius: 16px;
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }
    .vision-floor_graphic_1 {
      background: url(https://s.alicdn.com/@img/imgextra/i1/O1CN01ZUhAEZ1plFc5piQ6Z_!!6000000005400-0-tps-1408-1200.jpg)
        no-repeat center;
      background-size: cover;
      height: 493px;
    }
    .vision-floor_graphic_2 {
      background: url(https://s.alicdn.com/@img/imgextra/i3/O1CN01AuT5oO1CpaMXEBGOd_!!6000000000130-0-tps-1410-646.jpg)
        no-repeat center;
      background-size: cover;
      height: 265px;
      .vision-floor_graphic_desc {
        color: #51200b;
        width: calc(100% - 166px);
      }
      .vision-floor_graphic_title {
        width: calc(100% - 166px);
      }
    }
    .vision-floor_graphic_3 {
      background: url(https://s.alicdn.com/@img/imgextra/i2/O1CN01KXAelN1CWLiBLU7x9_!!6000000000088-0-tps-1408-490.jpg)
        no-repeat center;
      background-size: cover;
      height: 196px;
      margin-top: 32px;
    }

    .vision-floor_graphic_title {
      color: #f60;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .vision-floor_graphic_desc {
      font-size: 32px;
      line-height: 40px;
      &:first-child {
        -webkit-margin-after: 32px;
        /* margin-block-end:32px; */
      }
    }
    .vision-floor_names {
      color: #51200b;
      font-size: 14px;
      line-height: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 126px;
    }
    .vision-floor_graphic_desc,
    .vision-floor_graphic_title {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      font-weight: 600;
      margin-inline: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .countries-v4 {
    .countries-title {
      display: flex;
      justify-content: space-between;
      padding: 32px 0;
    }
    .right-wrapper {
      display: flex;
      align-items: center;
      .more-text {
        color: #222;
        font-size: 20px;
        font-weight: 500;
        margin-right: 7px;
        text-decoration: underline;
      }
      img {
        width: 16px;
        height: 14px;
      }
    }
    .home-country-item {
      text-align: center;
      .country-item-text {
        color: #222;
        font-size: 16px;
      }
    }
  }
  .full-link {
    li {
      list-style-type: none;
    }
    .full-link_item_wrapper {
      position: relative;
      padding-inline-start: 0px;
      .full-link_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
        &:not(:first-child) {
          margin-top: 20px;
        }
        .full-link_icon_wrapper {
          z-index: 1;
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          background-color: #fff;
          border: 2px solid #d8d8d8;
          border-radius: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-inline-end: 28px;
          .full-link_icon {
            width: 48px;
            height: 48px;
          }
        }
        .full-link_content {
          .full-link_item_title,
          .full-link_item_desc {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .full-link_item_title {
            font-size: 28px;
          }
          .full-link_item_desc {
            font-size: 16px;
            color: #767676;
            display: none;
            margin-top: 12px;
            -webkit-line-clamp: 4;
          }
        }
        &.full-link_item_enter {
          .full-link_item_title {
            color: #51200b;
            font-size: 36px;
            font-weight: 600;
            margin-top: -11px;
          }
          .full-link_icon_wrapper {
            background-color: #ffefe5;
            border-width: 0;
            box-shadow: 0 0 0 6px #fff;
            transform: scale(1.333);
          }
          .full-link_item_desc {
            display: -webkit-box !important;
          }
        }
      }

      .full-link_item_tail {
        background-color: #ddd;
        height: 100%;
        left: 28.5px;
        top: 0;
        position: absolute;
        width: 2px;
      }
    }
  }
  .factory-floor-entrance-container {
    .entrance-card-item {
      color: #fff;
      height: 379px;

      border-radius: 16px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.entrance-card-item1 {
        background: url(https://s.alicdn.com/@img/imgextra/i3/O1CN01blSupV1NpY5ZcwvIj_!!6000000001619-2-tps-920-920.png)
          no-repeat 50%;
        background-size: cover;
      }
      &.entrance-card-item2 {
        background: url(https://s.alicdn.com/@img/imgextra/i1/O1CN01twP5Jv1tjCIiCOQAv_!!6000000005937-2-tps-920-920.png)
          no-repeat 50%;
        background-size: cover;
      }
      &.entrance-card-item3 {
        background: url(https://s.alicdn.com/@img/imgextra/i1/O1CN01Lcuxd21Gs6zkRBFHe_!!6000000000677-2-tps-920-920.png)
          no-repeat 50%;
        background-size: cover;
      }
    }
  }
}
</style>