import Vue from 'vue'
import Snackbar from './Snackbar.vue'
let SnackbarConstructor = Vue.extend(Snackbar)

let bar = function () {
    const instance = new SnackbarConstructor({
        el: document.createElement('div'),
        data: {
            visibility: false,
        }
    })
    document.body.appendChild(instance.$el)
    instance.show = (options) => {
        instance.visibility = true
        instance.text = options?.text ?? options
        instance.color = null
    }
    instance.success = (options) => {
        instance.visibility = true
        instance.text = options?.text ?? options
        instance.color = "#4caf50"
    }
    instance.error = (options) => {
        instance.visibility = true
        instance.text = options?.text ?? options
        instance.color = "#f44336"
    }
    return instance
}
export default bar()
