import $api from '@/api'

const state = []

const mutations = {
    SET_BRAND_LIST: (state, list) => {
        state.length = 0
        state.push(...list)
    },
}

const actions = {
    requestBrandList ({ commit }) {
        return new Promise((resolve, reject) => {
            $api.brandHot().then(res => {
                if (res.state == "ok") {
                    commit('SET_BRAND_LIST', res.msg)
                }
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
